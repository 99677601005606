import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CodeAuthSettings from '../Auth';

import {
  Container,
  ActionsContainer,
  TitleBox,
  Typography,
  TypographyDescription,
  Divider,
  SettingsContent,
  Tooltip,
  Paper,
  Tabs,
  Tab,
  SwipeableViews,
} from './styles';

const SettingsPage = () => {
  const history = useHistory();

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <Container>
      <Paper>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          centered
        >
          <Tab label="Configurações do Farm" />
          <Tab label="Autenticação" />
        </Tabs>
      </Paper>
      <SwipeableViews index={tabIndex}>
        <ActionsContainer>
          <TitleBox>
            <Typography>Configurações do aplicativo</Typography>

            <TypographyDescription>
              Autenticação de usuários, parâmetros e grupos de acesso:
            </TypographyDescription>
          </TitleBox>
          <TitleBox>
            <Divider />
          </TitleBox>
          <TitleBox>
            <Tooltip title="Parâmetros da aplicação">
              <SettingsContent
                onClick={() => history.push('/settings/parameters')}
              >
                Parâmetros
              </SettingsContent>
            </Tooltip>
          </TitleBox>
        </ActionsContainer>
        <CodeAuthSettings />
      </SwipeableViews>
    </Container>
  );
};

export default SettingsPage;
