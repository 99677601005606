import { Switch, Route, Redirect } from 'react-router-dom';
import SessionsRoute from '~/modules/sessions/routes/session';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={SessionsRoute} />
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default Routes;
