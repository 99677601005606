/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';

import {
  CodeAuth,
  CodeAuthPassContainer,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  Link,
} from './styles';

const RecoverPasswordPage = ({ setIndex }) => {
  const [codeAuthLoad, setCodeAuthLoaded] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const passwordToken = query.get('password_token');

  const handleIframeToast = useCallback(
    event => {
      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'codeAuthLoaded'
      ) {
        setCodeAuthLoaded(true);
      }

      if (
        codeAuthLoad &&
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendToastValidationError'
      )
        toast.warn('Ocorreu um erro de validação');

      if (
        codeAuthLoad &&
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendUpdatePasswordSuccess'
      ) {
        setIndex(1);
        toast.success('Senha alterada com sucesso');
      }

      if (
        codeAuthLoad &&
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendTokenValidateError'
      ) {
        setIndex(1);
        toast.warn('Token inválido ou expirado');
        history.replace('');
      }

      if (
        codeAuthLoad &&
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendPasswordTokenInvalid'
      )
        setIndex(1);
    },
    [codeAuthLoad, setIndex, history]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeToast);
    return () => window.removeEventListener('message', handleIframeToast);
  }, [handleIframeToast]);

  return (
    <CodeAuth>
      <CodeAuthPassContainer
        onLoad={() => handleIframeToast}
        src={`${process.env.REACT_APP_CODE_AUTH_URL}/recover-password?password_token=${passwordToken}&url=${process.env.REACT_APP_APP_URL}`}
        width="100%"
        title="Alterar senha do Code Auth"
      />

      <ForgotPasswordContainer>
        <ForgotPasswordContent>
          <Link onClick={() => setIndex(1)}>
            <p>Voltar para a tela de Login</p>
          </Link>
        </ForgotPasswordContent>
      </ForgotPasswordContainer>
    </CodeAuth>
  );
};

export default RecoverPasswordPage;

RecoverPasswordPage.propTypes = {
  setIndex: PropTypes.func,
};

RecoverPasswordPage.defaultProps = {
  setIndex: null,
};
