import styled from 'styled-components';

import Dlg from '@material-ui/core/Dialog';
import ListItemTextStyled from '@material-ui/core/ListItemText';

export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as List } from '@material-ui/core/List';
export { default as ListItem } from '@material-ui/core/ListItem';
export { default as Button } from '@material-ui/core/Button';

export const Dialog = styled(Dlg).attrs({})`
  &.MuiDialog-root {
    .MuiPaper-root {
      padding: 2em 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }
`;

export const ListItemText = styled(ListItemTextStyled).attrs({
  primaryTypographyProps: {
    variant: 'h5',
    align: 'center',
  },
  secondaryTypographyProps: {
    variant: 'body2',
    align: 'left',
  },
})``;
