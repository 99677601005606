import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import { SharedReprocessUnitaryAppRepository } from '../repositories/app';

class SharedProcessUnitaryAppService {
  constructor() {
    this.sharedReprocessUnitaryAppRepository = new SharedReprocessUnitaryAppRepository();
  }

  async execute({ entityName, indicator, row, origin }) {
    try {
      await this.sharedReprocessUnitaryAppRepository.execute({
        entityName,
        row,
        origin,
        indicator,
      });

      return {};
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao reprocessa um ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'SharedProcessUnitaryAppService',
      };

      throw errors;
    }
  }
}

export default SharedProcessUnitaryAppService;
