import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedShowAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({ entityName = '', dataObj = {}, parser = null }) {
    try {
      const { data } = await this.apiApp
        .get(`/${plural(entityName).replace('_', '-')}/${dataObj.uuid}`)
        .then(res => res.data);

      if (parser) return { [entityName]: parser(data) };

      return data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao listar ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `BaseRepositoriesAppIndex`,
      });

      throw errors;
    }
  }
}

export default SharedShowAppRepository;
