import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import FormControl from '@material-ui/core/FormControl';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

function ChipInput({
  name,
  label,
  mask,
  helperText,
  helper,
  required,
  maxLength,
  endAdornment,
  ...rest
}) {
  const { fieldName, registerField, error, defaultValue } = useField(name);

  const inputRef = useRef({ value: '' });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl
      error={!!error}
      size="small"
      variant="outlined"
      color="secondary"
    >
      <Chip
        variant="outlined"
        color="primary"
        avatar={<Avatar>{label}</Avatar>}
        label={
          <input
            defaultValue={defaultValue}
            ref={inputRef}
            style={{ display: 'flex', border: 0, width: '300px' }}
            {...rest}
          />
        }
      />
    </FormControl>
  );
}

export default ChipInput;

ChipInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mask: PropTypes.func,
  helperText: PropTypes.string,
  helper: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  endAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ChipInput.defaultProps = {
  mask: null,
  helperText: '',
  helper: '',
  required: false,
  maxLength: null,
  endAdornment: null,
};
