import PropTypes from 'prop-types';
import { ThemeProvider as StyledTheme } from 'styled-components';
import {
  ThemeProvider as MaterialTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import useTheme from '~/shared/styles/theme';

import lightTheme from '~/shared/styles/theme/light';

import darkTheme from '~/shared/styles/theme/dark';

const AppTheme = ({ children }) => {
  const themeStyled = useTheme().title === 'light' ? lightTheme : darkTheme;

  const themeMaterial = createMuiTheme(
    {
      palette: {
        type: themeStyled.title === 'light' ? 'light' : 'dark',
        primary: {
          main:
            themeStyled.title === 'light'
              ? lightTheme.colors.primary
              : darkTheme.colors.primary,
        },
        secondary: {
          main:
            themeStyled.title === 'light'
              ? lightTheme.colors.secondary
              : darkTheme.colors.secondary,
        },
        third: {
          main:
            themeStyled.title === 'light'
              ? lightTheme.colors.third
              : darkTheme.colors.third,
        },
      },
    },
    ptBR
  );

  return (
    <StyledTheme theme={themeStyled}>
      <MaterialTheme theme={themeMaterial}>{children}</MaterialTheme>
    </StyledTheme>
  );
};

export default AppTheme;

AppTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
