import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Navigator from '~/shared/components/Navigator';
import ChangeBranch from '~/modules/sessions/pages/Branch';
import DashboardRoute from '~/modules/sessions/routes/dashboard';
import ProfileRoute from '~/modules/sessions/routes/profile';
import ProductsRoute from '~/modules/products/routes';
import SalesOrdersRoute from '~/modules/salesOrders/routes';
import SettingsRoute from '~/modules/settings/routes';

const Routes = () => {
  return (
    <Switch>
      <Route path="/changebranch" exact component={ChangeBranch} />
      <Navigator>
        <Switch>
          <Route path="/" exact component={DashboardRoute} />
          <Route path="/products" component={ProductsRoute} />
          <Route path="/profile" component={ProfileRoute} />
          <Route path="/sales-orders" component={SalesOrdersRoute} />
          <Route path="/settings" component={SettingsRoute} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Navigator>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
