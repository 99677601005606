import { plural } from 'pluralize';
import { SharedIndexSelectorAppRepository } from '../repositories/app';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedIndexSelectorAppService {
  constructor() {
    this.sharedIndexSelectorAppRepository = new SharedIndexSelectorAppRepository();
  }

  async execute({ entityName = '', search = '', parser = null }) {
    try {
      const baseData = await this.sharedIndexSelectorAppRepository.execute({
        entityName,
        search,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao listar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: 'SharedIndexSelectorAppService',
      };

      throw errors;
    }
  }
}

export default SharedIndexSelectorAppService;
