import { format, parseISO } from 'date-fns';
import { valueMask, cpfMask, cnpjMask } from '~/shared/providers/utils/mask';

import { parseIntegrationArrayValidations } from '~/shared/errors/handler';

export function parseToView(products = []) {
  return products.map(product => {
    const erp_id_created_at =
      product.erp_created_at !== null
        ? format(parseISO(product.erp_created_at), "dd'/'MM'/'yyyy - HH:mm")
        : '';
    const erp_id_updated_at =
      product.erp_updated_at !== null
        ? format(parseISO(product.erp_updated_at), "dd'/'MM'/'yyyy - HH:mm")
        : '';
    const ecommerce_id_created_at =
      product.ecommerce_created_at !== null
        ? format(
            parseISO(product.ecommerce_created_at),
            "dd'/'MM'/'yyyy - HH:mm"
          )
        : '';
    const ecommerce_id_updated_at =
      product.ecommerce_updated_at !== null
        ? format(
            parseISO(product.ecommerce_updated_at),
            "dd'/'MM'/'yyyy - HH:mm"
          )
        : '';
    const erp_id_process_at = product.erp_process_at
      ? format(parseISO(product.erp_process_at), "dd'/'MM'/'yyyy - HH:mm")
      : '';
    const ecommerce_id_process_at = product.ecommerce_process_at
      ? format(parseISO(product.ecommerce_process_at), "dd'/'MM'/'yyyy - HH:mm")
      : '';
    const id_updated_at = product.updated_at
      ? format(parseISO(product.updated_at), "dd'/'MM'/'yyyy - HH:mm")
      : '';

    const stock_erp_id_process_at = product.stock_erp_process_at
      ? format(parseISO(product.stock_erp_process_at), "dd'/'MM'/'yyyy - HH:mm")
      : '';

    let stock_balance_process_at = null;

    if (product.stock_erp_process_at) {
      stock_balance_process_at = format(
        parseISO(product.stock_erp_process_at),
        "dd'/'MM'/'yyyy - HH:mm"
      );
    } else if (product.stock_ecommerce_process_at) {
      stock_balance_process_at = format(
        parseISO(product.stock_ecommerce_process_at),
        "dd'/'MM'/'yyyy - HH:mm"
      );
    }

    let error_message = null;
    let error_message_details = null;
    const attempts_view = product.attempts || '0';

    try {
      try {
        const error = null;
        error_message = JSON.parse(product?.error_message)?.message;
        error_message_details = JSON.parse(product?.error_message)?.details;

        if (
          !error_message_details &&
          JSON.parse(product?.error_message).validations
        )
          throw error;
      } catch (error) {
        error_message = JSON.parse(product?.error_message)?.message;

        const arrToValidate = JSON.parse(product?.error_message)?.validations;

        error_message_details = parseIntegrationArrayValidations(arrToValidate);
      }
    } catch (error) {
      error_message = product?.error_message;
    }

    let ecommerce_id_icon = 'refresh';
    let ecommerce_id_status = 'Aguardando preenchimento';

    let erp_id_icon = 'refresh';
    let erp_id_status = 'Aguardando preenchimento';

    let id_icon = 'refresh';
    let id_status = 'Aguardando preenchimento';

    let stock_balance = null;
    let stock_balance_icon = 'refresh';
    let stock_balance_status = 'Aguardando preenchimento';

    let customer_first_name = '';
    let customer_cpf_cnpj = '';

    if (product.ecommerce_id) {
      ecommerce_id_icon = 'done';
      ecommerce_id_status = 'Integrado com sucesso';
    }

    if (product.erp_id) {
      erp_id_icon = 'done';
      erp_id_status = 'Integrado com sucesso';
    }

    if (product.stock_erp_process_at || product.stock_ecommerce_process_at) {
      stock_balance = product.stock_balance || '0';
      stock_balance_icon = 'done';
      stock_balance_status = 'Integrado com sucesso';
    }

    if (product.error_message) {
      id_icon = 'error';
      id_status = error_message;
    } else if (product.id) {
      id_icon = 'done';
    }

    if (product?.customer?.uuid) {
      customer_first_name = product.customer.first_name;
      customer_cpf_cnpj =
        product.customer.type === 'J'
          ? cnpjMask(product.customer.cpf_cnpj)
          : cpfMask(product.customer.cpf_cnpj);
    }

    return {
      ...product,
      ecommerce_id_icon,
      ecommerce_id_status,
      erp_id_icon,
      erp_id_status,
      total_value: valueMask(product.total_value),
      subtotal_value: valueMask(product.subtotal_value),
      shipping_value: valueMask(product.shipping_value),
      attempts_view,
      id_icon,
      customer_first_name,
      customer_cpf_cnpj,
      erp_id_created_at,
      erp_id_updated_at,
      ecommerce_id_created_at,
      erp_id_process_at,
      ecommerce_id_updated_at,
      ecommerce_id_process_at,
      id_updated_at,
      error_message,
      error_message_details,
      id_status,
      stock_erp_id_process_at,
      stock_balance,
      stock_balance_process_at,
      stock_balance_icon,
      stock_balance_status,
    };
  });
}

export function parseDataToDestroy(product = {}) {
  return {
    data: [
      {
        req_id: product.req_id,
        uuid: product.uuid,
        company_key: product.company_key,
        branch_key: product.branch_key,
      },
    ],
  };
}

export const formDataParser = formData => {
  return {
    type: formData.type,
    indicator: formData.indicator,
    start_at: formData.start_at,
  };
};

export const formUnitaryIntegrationParser = formData => {
  return {
    entity: formData.entity,
    origin: formData.origin,
  };
};
