import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { Error as ErrorIcon, Help } from '@material-ui/icons';

import { TagsContainer, Error } from './styles';

function Tag({ tags, setTags, value, setValue }) {
  const handleChange = event => {
    setValue(event.target.value);
  };

  function removeTag(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);

    if (!newTags.length) setValue('');
  }

  function inputKeyDown(e) {
    const val = e.target?.value;
    if (e.key === ',' && val) {
      const checkDuplicate = tags?.find(
        tag => tag.toLowerCase() === val.toLowerCase()
      );

      if (checkDuplicate) return;

      const auxTags = [...tags, val.replace(',', '')];

      setTags(auxTags);
      setValue('');
    } else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1);
    }
  }

  function handleOnBlur() {
    inputKeyDown({
      target: { value: value.replace(',', '') },
      key: ',',
    });
  }

  return (
    <TagsContainer className="input-tag">
      <ul className="input-tag__tags">
        {tags?.map((tag, index) => (
          <li key={tag}>
            {tag}
            <button type="button" onClick={() => removeTag(index)}>
              x
            </button>
          </li>
        ))}

        <li className="input-tag__tags__input">
          <input
            type="text"
            onKeyDown={inputKeyDown}
            value={value?.replace(',', '')}
            onChange={handleChange}
            onBlur={handleOnBlur}
          />
        </li>
      </ul>
    </TagsContainer>
  );
}

Tag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

function InputTag({ name, label, helperText, helper, required, ...rest }) {
  const [value, setValue] = useState('');
  const [tags, setTags] = useState([]);

  const { fieldName, registerField, error, clearError } = useField(name);

  const inputRef = useRef({ value: '' });

  function handleChangeTags(newTags) {
    inputRef.current.value = newTags.toString();
    setTags(newTags);
  }

  useEffect(() => {
    inputRef.current.handleChange = handleChangeTags;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl
      error={!!error}
      size="small"
      variant="outlined"
      style={{ maxWidth: '200px' }}
      focused={!!tags.length}
    >
      <InputLabel>
        <p>
          {label} {required && <b style={{ color: '#d50000' }}>*</b>}
        </p>
      </InputLabel>

      <OutlinedInput
        value={value}
        onChange={error && clearError}
        inputComponent={Tag}
        inputRef={inputRef}
        inputProps={{
          ...rest,
          tags,
          setTags: handleChangeTags,
          value,
          setValue,
        }}
        label={`${label}${required ? ' *' : ''}`}
        error={!!error}
        {...rest}
      />

      <FormHelperText>{helperText || ' '}</FormHelperText>

      {helper && (
        <Error type="help" title={helper}>
          <Help size={20} color="primary" />
        </Error>
      )}

      {error && (
        <Error title={error}>
          <ErrorIcon size={20} color="error" />
        </Error>
      )}
    </FormControl>
  );
}

export default InputTag;

InputTag.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  helper: PropTypes.string,
  required: PropTypes.bool,
};

InputTag.defaultProps = {
  required: false,
  helperText: '',
  helper: '',
};
