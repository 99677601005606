import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { Error as ErrorIcon, Help } from '@material-ui/icons';

import { Unmask } from '~/shared/providers/utils/mask';

import { CharacterLimit, Error } from './styles';

function TextInput({
  name,
  label,
  mask,
  helperText,
  helper,
  required,
  maxLength,
  endAdornment,
  ...rest
}) {
  const {
    fieldName,
    registerField,
    error,
    clearError,
    defaultValue,
  } = useField(name);

  const inputRef = useRef({ value: '' });
  const [counter, setCounter] = useState(0);

  const handleChange = event => {
    if (error) clearError();

    if (inputRef.current) {
      if (mask) inputRef.current.value = mask(event.target.value);

      if (maxLength) setCounter(Unmask(inputRef.current.value).length);
    }
  };

  useEffect(() => {
    if (mask && inputRef.current)
      inputRef.current.value = mask(inputRef.current.value);
  }, [mask]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl
      error={!!error}
      size="small"
      variant="outlined"
      color="primary"
    >
      <InputLabel variant="outlined" color="primary">
        {label} {required && <b style={{ color: '#d50000' }}>*</b>}
      </InputLabel>
      <OutlinedInput
        inputRef={inputRef}
        onChange={handleChange}
        defaultValue={defaultValue}
        label={`${label}${required ? ' *' : ''}`}
        error={!!error}
        inputProps={{ maxLength: maxLength || 255 }}
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          )
        }
        {...rest}
      />
      {maxLength && (
        <CharacterLimit>
          {counter}/{maxLength}
        </CharacterLimit>
      )}
      <FormHelperText>{helperText || ' '}</FormHelperText>

      {helper && (
        <Error type="help" title={helper}>
          <Help size={20} color="primary" />
        </Error>
      )}

      {error && (
        <Error title={error}>
          <ErrorIcon size={20} color="error" />
        </Error>
      )}
    </FormControl>
  );
}

export default TextInput;

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mask: PropTypes.func,
  helperText: PropTypes.string,
  helper: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  endAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TextInput.defaultProps = {
  mask: null,
  helperText: '',
  helper: '',
  required: false,
  maxLength: null,
  endAdornment: null,
};
