import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  description: Yup.string().required('Este campo é obrigatório'),
  key: Yup.string().required('Este campo é obrigatório'),
  value: Yup.string().required('Este campo é obrigatório'),
  indicator: Yup.string().required('Este campo é obrigatório'),
});

export default schema;
