import {
  Route,
  Redirect,
  useLocation,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSession } from '~/modules/sessions/hooks/useSession';

import ParametersRoute from '../parameters/routes';
import Settings from '../pages/Settings';

const SettingsRoute = () => {
  const location = useLocation();
  const { path } = useRouteMatch();

  const { current_accesses } = useSession();

  if (
    current_accesses.roles['farm.admin'] ||
    current_accesses.roles['farm.manager'] ||
    current_accesses.permissions['farm.parameters.get']
  ) {
    return (
      <Switch>
        <Route path={path} exact component={Settings} />
        <Route path={`${path}/parameters`} component={ParametersRoute} />
        <Route path="*">
          <Redirect to={path} />
        </Route>
      </Switch>
    );
  }

  if (location.pathname === '/settings') {
    toast.warning(
      'Usuário não tem permissão para acessar a página de Configuração'
    );

    return <Redirect to="/" />;
  }

  return <></>;
};

export default SettingsRoute;
