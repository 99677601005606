import styled from 'styled-components';

import ListItemTextStyled from '@material-ui/core/ListItemText';
import ChipStyled from '@material-ui/core/Chip';
import ButtonStyled from '@material-ui/core/Button';

export { default as Divider } from '@material-ui/core/Divider';
export { default as DeleteForever } from '@material-ui/icons/DeleteForever';
export { default as ErrorOutline } from '@material-ui/icons/ErrorOutline';

export const Container = styled.div`
  padding: 0.5em;
`;

export const MenuHeadText = styled(ListItemTextStyled).attrs({
  primaryTypographyProps: {
    component: 'div',
  },
  secondaryTypographyProps: {
    component: 'div',
  },
})``;

export const MenuHeadContainer = styled.div`
  display: flex;

  div {
    margin-left: 1em;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;

  gap: 20px;
`;

export const SelectorText = styled(ListItemTextStyled).attrs({
  primaryTypographyProps: {
    component: 'div',
  },
  secondaryTypographyProps: {
    component: 'div',
  },
})``;

export const Chip = styled(ChipStyled).attrs({
  size: 'small',
  variant: 'outlined',
  color: 'primary',
})``;

export const MenuButtonContainer = styled.div`
  display: flex;

  justify-content: space-around;
`;

export const MenuButton = styled(ButtonStyled).attrs(props => ({
  variant: props.remove || props.json ? 'outlined' : 'contained',
  color: props.remove ? 'default' : 'primary',
}))`
  ${props =>
    props.remove
      ? `
  border-color: #b71c1c;
  text-color: #b71c1c;
  `
      : ''}
`;
