import styled from 'styled-components';

import DialogStyled from '@material-ui/core/Dialog';
import ListStyled from '@material-ui/core/List';
import ListItemTextStyled from '@material-ui/core/ListItemText';
import TypographyStyled from '@material-ui/core/Typography';
import ChipStyled from '@material-ui/core/Chip';
import BoxStyled from '@material-ui/core/Box';
import GridStyled from '@material-ui/core/Grid';

export { default as Divider } from '@material-ui/core/Divider';

export const Dialog = styled(DialogStyled).attrs({})`
  &.MuiDialog-root {
    .MuiPaper-root {
      min-width: 450px;
      padding: 1em;
    }
  }
`;

export const List = styled(ListStyled).attrs({})``;

export const ListContained = styled(ListStyled).attrs({
  component: 'div',
})`
  overflow: auto;
  max-height: 50vh;
`;

export const ListItemText = styled(ListItemTextStyled).attrs({
  primaryTypographyProps: {
    component: 'div',
  },
  secondaryTypographyProps: {
    component: 'div',
  },
})``;

export const Typography = styled(TypographyStyled).attrs(props => ({
  component: 'div',
  variant: props.title ? 'h5' : 'body2',
}))``;

export const Chip = styled(ChipStyled).attrs({
  variant: 'default',
  color: 'primary',
  size: 'small',
})``;

export const Box = styled(BoxStyled).attrs(props => ({
  mt: props.divider ? 2 : 0,
}))``;

export const ContainerBox = styled(BoxStyled).attrs({})``;

export const Grid = styled(GridStyled).attrs({})``;
