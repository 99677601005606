export const entityName = 'sales_order';

export const tableListOptions = {
  headCells: [
    { id: 'id', label: 'Código Farm', exclude: true, isId: true },
    { id: 'ecommerce_id', label: 'ID E-commerce', exclude: true },
    { id: 'erp_id', label: 'ID ERP', exclude: true },
    { id: 'sales_order_id', label: 'Código Pedido de Venda', exclude: true },
  ],
  typesOptions: [{ id: 'process_all', name: 'Reprocesso Total' }],
  reprocessOptions: [
    { id: 'sales_order', name: 'Pedidos de Vendas' },
    { id: 'invoice', name: 'NFE' },
  ],
  otherCells: [
    { id: 'attempts_view', label: 'Tentativas de reprocesso', exclude: true },
    { id: 'customer_name', label: 'Cliente', exclude: true },
    { id: 'invoice', label: 'Nota Fiscal', exclude: true },
    {
      id: 'customer_ecommerce_id',
      label: 'Cód. Cliente E-commerce',
      exclude: true,
    },
    {
      id: 'customer_erp_id',
      label: 'Cód. Cliente ERP',
      exclude: true,
    },
    {
      id: 'purchase_order_ecommerce_id',
      label: 'Cód. Pedido Compra E-commerce',
      exclude: true,
    },
    {
      id: 'purchase_order_erp_id',
      label: 'Cód. Pedido Compra ERP',
      exclude: true,
    },
  ],
  observationCells: [
    { id: 'general_observations', label: 'Observações Gerais' },
    { id: 'internal_observations', label: 'Observações Internas' },
  ],
  filtersOptions: [
    { id: 'id', label: 'Código Farm', type: 'string' },
    { id: 'erp_id', label: 'ID ERP', type: 'string' },
    { id: 'ecommerce_id', label: 'ID E-COMMERCE', type: 'string' },
    { id: 'sales_order_id', label: 'Código Pedido de Venda', type: 'string' },
    { id: 'erp_process_at', label: 'Data processo ERP', type: 'date' },
    {
      id: 'ecommerce_process_at',
      label: 'Data processo E-COMMERCE',
      type: 'date',
    },
    { id: 'updated_at', label: 'Data processo Farm', type: 'date' },
    { id: 'error_message', label: 'Mensagem de Erro', type: 'string' },
  ],
};
