import styled from 'styled-components';
import Btn from '@material-ui/core/Button';

export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as Equalizer } from '@material-ui/icons/Equalizer';
export { default as ErrorIcon } from '@material-ui/icons/Error';

export const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
`;

export const WelcomeContainer = styled.div`
  margin-bottom: 2em;
`;

export const WelcomeText = styled.div`
  padding: 0 1.5em;
  h1 {
    color: ${props => props.theme.colors.primaryText};
    font-weight: 500;
  }
  margin-bottom: 1em;
`;

export const WelcomeCards = styled.div`
  display: flex;
  gap: 20px;
`;

export const Card = styled.div`
  display: flex;
  border-radius: 5px;
  gap: 10px;
  color: ${props => props.theme.colors.secondaryText};
  padding: 1em;
  width: 370px;
  height: 111px;
  ${props =>
    props.type === 'month_total' &&
    `
    background: linear-gradient(#41cced 0%, #003fa3 100%);
  `}
  ${props =>
    props.type === 'year_total' &&
    `
    background: linear-gradient(#2ced60 0%, #00402c 97.04%);
  `}
  ${props =>
    props.type === 'error_total' &&
    `
    background: linear-gradient(#f23009 0%, #731503 100%);
  `}
`;

export const CardIcon = styled.div``;

export const CardInfo = styled.div`
  h2 {
    font-size: 1.7rem;
    span {
      font-size: 1rem;
      font-weight: 400;
      margin-right: 0.3em;
    }
  }
  p {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
  variant: 'contained',
})`
  &.MuiButtonBase-root {
    margin-top: 10px;
  }
`;

export const CardContent = styled.div`
  & + div {
    margin-top: 2rem;
  }
`;
export const CardList = styled.div`
  margin-top: 2rem;
  height: calc(70vh - 50px);
  overflow: auto;
`;
