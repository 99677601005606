import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Profile from '../pages/Profile';

const ProfileRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={Profile} />
    </Switch>
  );
};

export default ProfileRoute;
