import PropTypes from 'prop-types';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function TableListHead({
  classes,
  order,
  orderBy,
  onRequestSort,
  headCells,
  actions,
  select,
}) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.image ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {select ? (
          <TableCell align="center">Selecionar</TableCell>
        ) : (
          actions && <TableCell align="center">Ações</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export default TableListHead;

export const actionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    isNav: PropTypes.bool,
    disabled: PropTypes.bool,
    handleFunction: PropTypes.func.isRequired,
  })
);

export const selectPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  handleFunction: PropTypes.func.isRequired,
});

export const cellsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    image: PropTypes.bool,
    exclude: PropTypes.bool,
  })
);

TableListHead.propTypes = {
  classes: PropTypes.shape().isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: cellsPropType,
  actions: actionsPropType,
  select: selectPropType,
};

TableListHead.defaultProps = {
  headCells: null,
  actions: null,
  select: null,
};
