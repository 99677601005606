import styled, { css } from 'styled-components';

import Dlg from '@material-ui/core/Dialog';
import TypographyStyled from '@material-ui/core/Typography';
import BoxStyled from '@material-ui/core/Box';
import ButtonStyled from '@material-ui/core/Button';
import DialogTitleStyled from '@material-ui/core/DialogTitle';

export { default as List } from '@material-ui/core/List';
export { default as ListItem } from '@material-ui/core/ListItem';

export const Dialog = styled(Dlg).attrs({})`
  &.MuiDialog-root {
    .MuiPaper-root {
      display: flex;
      padding: 1em;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const DialogTitle = styled(DialogTitleStyled).attrs({})`
  &.MuiDialogTitle-root {
    padding: 0;
  }
`;

export const Typography = styled(TypographyStyled).attrs({
  component: 'p',
  color: 'textSecondary',
})`
  width: 100%;
`;

export const Box = styled(BoxStyled).attrs({
  m: 1,
})`
  ${props =>
    props.row === 'true' &&
    css`
      display: flex;
      flex-direction: row;
      gap: 10px;
    `}
`;

export const Button = styled(ButtonStyled).attrs(props => ({
  variant: props.cancel ? 'outlined' : 'contained',
  color: 'primary',
}))``;
