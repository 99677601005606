export default {
  title: 'dark',

  logo: process.env.REACT_APP_COMPANY_LOGO_PATH,
  company: process.env.REACT_APP_COMPANY,

  colors: {
    primary: '#121212',
    secondary: '#282828',
    secondaryHover: '#202020',
    third: '#202020',
    primaryText: '#ffff',
    secondaryText: '#151515',
    error: 'red',
    passed: 'green',
  },
};
