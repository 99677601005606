import React from 'react';
import PropTypes from 'prop-types';

import noImage from '~/shared/assets/noImage.png';

import { Container } from './styles';

function ImageContainer({ status, src, alt, size, ...rest }) {
  const [image, errorImage] = src;

  function onErrorImage(event) {
    event.target.src = errorImage || noImage; // eslint-disable-line
  }

  return (
    <Container status={status} size={size} {...rest}>
      <img onError={onErrorImage} src={image || noImage} alt={alt} />
    </Container>
  );
}

export default ImageContainer;

ImageContainer.propTypes = {
  status: PropTypes.number,
  src: PropTypes.arrayOf(PropTypes.string),
  alt: PropTypes.string,
  size: PropTypes.number,
};

ImageContainer.defaultProps = {
  status: null,
  src: [],
  alt: '',
  size: 50,
};
