import styled from 'styled-components';
import Dlg from '@material-ui/core/Dialog';
import DlgTitle from '@material-ui/core/DialogTitle';
import DlgContent from '@material-ui/core/DialogContent';
import DlgContentText from '@material-ui/core/DialogContentText';
import DlgActions from '@material-ui/core/DialogActions';
import Btn from '@material-ui/core/Button';

export const Dialog = styled(Dlg)``;

export const DialogActions = styled(DlgActions)``;

export const DialogContent = styled(DlgContent)``;

export const DialogContentText = styled(DlgContentText)``;

export const DialogTitle = styled(DlgTitle)``;

export const Button = styled(Btn).attrs({
  color: 'primary',
  disableElevation: true,
})``;

export const ToastButton = styled.button`
  display: flex;
  flex: 1;

  border: none;
  background: transparent;
`;
