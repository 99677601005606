import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class StoreApp {
  constructor() {
    this.apiApp = apiApp;
  }

  async store({ entityName = '', dataObj = {}, parser = null }) {
    try {
      const { data } = await this.apiApp
        .post(`/${plural(entityName).replace('_', '-')}`, { data: [dataObj] })
        .then(res => res.data);

      if (data[0].status !== 200) {
        throw data[0].error;
      }

      if (parser) return parser([{ ...data[entityName] }])[0];

      return data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao criar um ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'BaseRepositoriesAppStore',
      });

      throw errors;
    }
  }
}

export default StoreApp;
