import PropTypes from 'prop-types';
import { Grid, Loading } from '~/shared/components/Form';
import { Dialog, DialogTitle, Button, Typography, Box } from './styles';

export default function WarningDialog({
  showDialog,
  setShowDialog,
  description,
  loading,
  onSuccess,
}) {
  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog onClose={handleClose} open={showDialog}>
      <DialogTitle style={{ padding: 0 }}>Atenção</DialogTitle>
      <Grid xs={12} item="true">
        <Box>
          <Typography>{description}</Typography>
        </Box>
        <Box row="true">
          <Button
            disabled={loading}
            type="button"
            cancel="cancel"
            onClick={handleClose}
          >
            Cancelar
            {loading && <Loading color="secondary" />}
          </Button>
          <Button disabled={loading} type="button" onClick={onSuccess}>
            Remover
            {loading && <Loading color="secondary" />}
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
}

WarningDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
};

WarningDialog.defaultProps = {
  onSuccess: () => {},
};
