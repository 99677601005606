import { createContext, useCallback, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import apiApp from '~/config/apiApp';

import { SessionAppService, SessionDashboardAppService } from '../services';

import { useErrorHandler } from '~/shared/errors/hook';
import * as store from './useLocalStorage';

const INITIAL_STATE = {
  email: '',
  password: '',
  signed: false,
  token: {},
  user: {},
  contract: {},
  companies: [],
  branches: [],
  current_accesses: [],
  logged_branch: {},
  sessionErrors: {},
  sessionPageIndex: 1,
  sessionLoading: false,
};

const sessionAppService = new SessionAppService();
const sessionDashboardAppService = new SessionDashboardAppService();

const SessionContext = createContext(INITIAL_STATE);

export function SessionProvider({ children }) {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const appUrl = query.get('url');

  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(() => {
    const sessionData = store.getData();

    if (sessionData?.token?.token && sessionData?.signed) {
      apiApp.defaults.headers.authorization = `Bearer ${sessionData.token.token}`;
      apiApp.defaults.headers[
        'Refresh-Token'
      ] = `${sessionData.token.refreshToken}`;
      return { ...INITIAL_STATE, ...sessionData };
    }

    const {
      contract: { uuid },
    } = INITIAL_STATE;
    if (uuid) store.setData(uuid);

    store.setData(INITIAL_STATE);
    return INITIAL_STATE;
  });

  const setSessionData = useCallback((newData = INITIAL_STATE) => {
    store.setData(newData);
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const dashboardInfo = useCallback(async () => {
    try {
      setSessionData({ sessionLoading: true });

      const sessionData = await sessionDashboardAppService.execute();

      setSessionData({ sessionLoading: false });

      return sessionData;
    } catch (error) {
      setErrorHandlerData({
        ...error,
        resolveFunction: () => dashboardInfo(),
      });
      setSessionData({ sessionLoading: false });
      return {};
    }
  }, [setErrorHandlerData, setSessionData]);

  const signIn = useCallback(
    async ({ email = '', password = '', branch_key = '' }) => {
      setSessionData({
        email,
        password,
        sessionErrors: {},
        sessionLoading: true,
      });
      try {
        const sessionData = await sessionAppService.store({
          email,
          password,
          branch_key,
        });

        if (sessionData?.token) {
          sessionData.signed = true;
        } else if (data.sessionPageIndex === 1) {
          sessionData.sessionPageIndex = data.sessionPageIndex + 1;
        } else {
          sessionData.email = '';
          sessionData.password = '';
        }

        setSessionData({ ...sessionData });
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => signIn({ email, password, branch_key }),
        });

        throw error;
      } finally {
        setSessionData({ sessionLoading: false });
      }
    },
    [setSessionData, setErrorHandlerData, data.sessionPageIndex]
  );

  const changeBranch = useCallback(
    async ({ branch_key = '' }) => {
      setSessionData({ sessionLoading: true });
      try {
        const sessionData = await sessionAppService.changeBranch({
          branch_key,
        });
        setSessionData({ ...sessionData, sessionLoading: false });
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => changeBranch({ branch_key }),
        });
        setSessionData({ sessionLoading: false });
      }
    },
    [setSessionData, setErrorHandlerData]
  );

  const signOut = useCallback(() => {
    store.setData(INITIAL_STATE);

    apiApp.defaults.headers.authorization = null;
    apiApp.defaults.headers['Refresh-Token'] = null;

    setSessionData(INITIAL_STATE);

    window.parent.postMessage('signOut', appUrl);
    history.push('/');
  }, [setSessionData, history, appUrl]);

  const refreshToken = useCallback(async () => {
    try {
      setSessionData({ sessionLoading: true });

      const sessionData = await sessionAppService.refreshToken();

      setSessionData({ ...sessionData, authLoading: false });
    } catch (error) {
      setErrorHandlerData({
        ...error,
        resolveFunction: () => refreshToken(),
      });

      signOut();
      setSessionData({ authLoading: false });
    }
  }, [setSessionData, signOut, setErrorHandlerData]);

  const refreshTokenFromExternalApp = useCallback(
    async ({ token }) => {
      setSessionData({ sessionLoading: true });

      try {
        setSessionData({
          token,
        });

        const sessionData = store.getData();

        if (sessionData?.token && sessionData?.signed) {
          apiApp.defaults.headers.authorization = `Bearer ${sessionData.token.token}`;
          apiApp.defaults.headers[
            'Refresh-Token'
          ] = `${sessionData.token.refreshToken}`;
        }
      } catch (err) {
        setErrorHandlerData({
          error: {
            ...err,
          },
        });
        throw err;
      } finally {
        setSessionData({ sessionLoading: false });
      }
    },
    [setErrorHandlerData, setSessionData]
  );

  return (
    <SessionContext.Provider
      value={{
        ...data,
        signIn,
        changeBranch,
        signOut,
        refreshToken,
        refreshTokenFromExternalApp,
        dashboardInfo,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);

  if (!context)
    throw new Error('useSession must be used within an SessionProvider');

  return context;
}

SessionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
