import { plural } from 'pluralize';
import { SharedListAppRepository } from '../repositories/app';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedListAppService {
  constructor() {
    this.sharedListAppRepository = new SharedListAppRepository();
  }

  async execute({
    entityName = '',
    search = '',
    order_by = '',
    order = '',
    page = 1,
    filter_by = '',
    parser,
  }) {
    try {
      const baseData = await this.sharedListAppRepository.execute({
        entityName,
        order,
        order_by,
        page,
        search,
        filter_by,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao listar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: 'ListBaseServiceExecute',
      };

      throw errors;
    }
  }
}

export default SharedListAppService;
