import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import { SharedStoreAppRepository } from '../repositories/app';

class StoreBaseService {
  constructor() {
    this.sharedStoreAppRepository = new SharedStoreAppRepository();
  }

  async execute({ entityName = '', dataObj = {}, parser }) {
    try {
      const baseData = await this.sharedStoreAppRepository.store({
        entityName,
        dataObj,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao criar ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `StoreBaseServiceExecute`,
      };

      throw errors;
    }
  }
}

export default StoreBaseService;
