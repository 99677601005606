import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSession } from '~/modules/sessions/hooks/useSession';

import {
  clsx,
  useStyles,
  CssBaseline,
  Divider,
  IconButton,
  Tooltip,
  ListItem,
  Container,
  HeaderContent,
  LogoContent,
  MenuButton,
  DrawerContent,
  Main,
  RouteContent,
  RouteTitle,
  FacileLogoContent,
  FacileWelcomeContent,
  Content,
  Popper,
  ClickAwayListener,
  MenuContainer,
  MenuActionContainer,
  MenuArrowContainer,
  MenuBranch,
  MenuUser,
  List,
  ListItemAvatar,
  ListItemIconContent,
  ListItemTitle,
  Avatar,
  AvatarImageContainer,
  Typography,
  Box,
  MenuIcon,
  ChevronLeft,
  ArrowBackIosIcon,
  DescriptionIcon,
  SwapHoriz,
  Business,
  BusinessCenter,
  ExpandMore,
  Shop,
  Person,
  Settings,
  DashboardIcon,
} from './styles';

import noImage from '~/shared/assets/noImage.png';

export default function Navigation({ children }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { user, logged_branch, signOut, current_accesses } = useSession();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const userMenuId = userMenuOpen ? 'spring-popper' : undefined;

  const locationPage = useMemo(() => {
    const locate = location?.pathname.split('/');

    const pagesDict = {
      customers: 'Clientes',
      profile: 'Perfil',
      'sales-orders': 'Pedidos de vendas',
      users: 'Usuários',
      products: 'Produtos',
      parameters: 'Parâmetros',
      settings: 'Configurações',
    };

    return {
      title: pagesDict[locate[1]] || 'Dashboard',
      subTitle: locate.length >= 3 ? pagesDict[locate[2]] : null,
    };
  }, [location]);

  const handleChangeBranch = () => {
    history.push('/changebranch');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
    setUserMenuOpen(prev => !prev);
  };

  const handleCloseMenu = () => {
    setUserMenuOpen(prev => !prev);
  };

  function handleMenuNavigation(pathRoute) {
    history.push(pathRoute);
    setUserMenuOpen(false);
  }

  async function handleSignOut() {
    await signOut();
  }

  return (
    <Container className={classes.root}>
      <CssBaseline />
      <HeaderContent
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <LogoContent>
          <MenuButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon color="primary" />
          </MenuButton>
          <Link to="/">
            <Box />
          </Link>
        </LogoContent>
        <FacileLogoContent>
          <div>
            <Popper
              id={userMenuId}
              anchorEl={anchorEl}
              open={userMenuOpen}
              placement="bottom-start"
              className={classes.popperRoot}
            >
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuContainer
                  initial={{
                    height: 0,
                    border: '1px solid #fff',
                    borderTop: 'none',
                    borderRadius: '0px 0px 5px 5px',
                  }}
                  animate={
                    userMenuOpen
                      ? {
                          height: [0, 350],
                        }
                      : { height: [350, 0] }
                  }
                  transition={{ duration: 0.2 }}
                >
                  <MenuBranch>
                    <List>
                      <MenuUser
                        button
                        onClick={() => handleMenuNavigation('/profile')}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <Person />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemTitle
                          primary={
                            <Typography
                              variant="subtitle1"
                              align="left"
                              display="block"
                              noWrap
                            >
                              {user.name}
                            </Typography>
                          }
                          secondary={<strong>Usuário</strong>}
                        />
                      </MenuUser>
                      <MenuUser>
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessCenter />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemTitle
                          primary={
                            <Typography
                              variant="subtitle1"
                              align="left"
                              display="block"
                              noWrap
                            >
                              {logged_branch.company.name}
                            </Typography>
                          }
                          secondary={<strong>Empresa</strong>}
                        />
                      </MenuUser>
                      <MenuUser>
                        <ListItemAvatar>
                          <Avatar>
                            <Business />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemTitle
                          primary={
                            <Typography
                              variant="subtitle1"
                              align="left"
                              display="block"
                              noWrap
                            >
                              {logged_branch.first_name}
                            </Typography>
                          }
                          secondary={<strong>Filial</strong>}
                        />
                      </MenuUser>
                    </List>
                  </MenuBranch>
                  <Divider />
                  <MenuBranch>
                    <List>
                      <MenuActionContainer
                        initial={{
                          opacity: 0,
                        }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        <MenuUser button onClick={handleChangeBranch}>
                          <ListItemAvatar>
                            <Avatar>
                              <SwapHoriz />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemTitle
                            primary={
                              <Typography
                                variant="subtitle1"
                                align="left"
                                display="block"
                                noWrap
                              >
                                Alterar Empresa
                              </Typography>
                            }
                            secondary={<strong>Empresa/Filial</strong>}
                          />
                        </MenuUser>
                      </MenuActionContainer>
                    </List>
                  </MenuBranch>
                </MenuContainer>
              </ClickAwayListener>
            </Popper>
          </div>
          <FacileWelcomeContent
            onClick={handleClickMenu}
            isopen={!userMenuOpen ? 'notOpen' : 'open'}
          >
            <AvatarImageContainer
              initial={{ x: '0%' }}
              animate={
                userMenuOpen
                  ? { x: '109px', scale: '1.4' }
                  : { x: '0px', scale: '1' }
              }
            >
              <Avatar alt={user.name} src={user.image || noImage} />
              {!userMenuOpen && <span>{user.name}</span>}
            </AvatarImageContainer>
            <MenuArrowContainer
              initial={{ rotate: 0 }}
              animate={userMenuOpen ? { rotate: 180 } : { rotate: 0 }}
            >
              <ExpandMore fontSize="large" />
            </MenuArrowContainer>
          </FacileWelcomeContent>
        </FacileLogoContent>
      </HeaderContent>
      <DrawerContent
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ArrowBackIosIcon color="primary" />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip title="Dashboard" arrow placement="right-start">
            <ListItem button onClick={() => history.push('/')}>
              <ListItemIconContent navigate={`${location.pathname === '/'}`}>
                <DashboardIcon size={28} />
              </ListItemIconContent>
              <ListItemTitle primary="Dashboard" />
            </ListItem>
          </Tooltip>
          {(current_accesses?.roles['farm.admin'] ||
            (current_accesses?.roles['farm.manager'] &&
              current_accesses?.permissions['farm.sales-orders.all']) ||
            (current_accesses?.permissions['farm.sales-orders.get'] &&
              current_accesses?.permissions['farm.sales-orders.all'])) && (
            <Tooltip title="Vendas" arrow placement="right-start">
              <ListItem button onClick={() => history.push('/sales-orders')}>
                <ListItemIconContent
                  navigate={`${location.pathname === '/sales-orders'}`}
                >
                  <DescriptionIcon size={28} />
                </ListItemIconContent>
                <ListItemTitle primary="Vendas" />
              </ListItem>
            </Tooltip>
          )}

          {(current_accesses?.roles['farm.admin'] ||
            (current_accesses?.roles['farm.manager'] &&
              current_accesses?.permissions['farm.products.all']) ||
            (current_accesses?.permissions['farm.products.get'] &&
              current_accesses?.permissions['farm.products.all'])) && (
            <Tooltip title="Produtos" arrow placement="right-start">
              <ListItem button onClick={() => history.push('/products')}>
                <ListItemIconContent
                  navigate={`${location.pathname === '/products'}`}
                >
                  <Shop size={28} />
                </ListItemIconContent>
                <ListItemTitle primary="Produtos" />
              </ListItem>
            </Tooltip>
          )}
        </List>

        <Divider />
        <List>
          {(current_accesses?.roles['farm.admin'] ||
            current_accesses?.roles['farm.manager'] ||
            current_accesses?.permissions['farm.parameters.get']) && (
            <Tooltip title="Configurações" arrow placement="right-start">
              <ListItem button onClick={() => history.push('/settings')}>
                <ListItemIconContent
                  navigate={`${location.pathname === '/settings'}`}
                >
                  <Settings size={28} />
                </ListItemIconContent>
                <ListItemTitle primary="Configurações" />
              </ListItem>
            </Tooltip>
          )}
        </List>

        <Divider style={{ marginTop: 'auto' }} />
        <List>
          <ListItem button onClick={handleSignOut}>
            <ListItemIconContent>
              <ChevronLeft size={28} />
            </ListItemIconContent>
            <ListItemTitle primary="Sair" />
          </ListItem>
        </List>
      </DrawerContent>
      <Main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <RouteContent>
          {location.pathname !== '/' ? (
            <IconButton
              onClick={() => {
                history.goBack();
              }}
            >
              <ChevronLeft />
            </IconButton>
          ) : (
            <div />
          )}
          <RouteTitle>{locationPage.title}</RouteTitle>
          <div />
        </RouteContent>
        <Content>{children}</Content>
      </Main>
    </Container>
  );
}

Navigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
