import PropTypes from 'prop-types';
import {
  Dialog,
  List,
  ListContained,
  ListItemText,
  Typography,
  Divider,
  Chip,
  Box,
  Grid,
  ContainerBox,
} from './styles';

export default function IntegrationError({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  const verifyHtmlRegex = /<[^>]+>\s+(?=<)|<[^>]+>/g;

  function verifyHtml() {
    if (
      typeof data?.error_message_details === 'string' &&
      data?.error_message_details?.match?.(verifyHtmlRegex)
    ) {
      return (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: data.error_message_details }} />
      );
    }

    return (
      <div>
        {JSON.stringify(data?.error_message_details || data?.error_message)}
      </div>
    );
  }

  // eslint-disable-next-line consistent-return
  const renderIfLoaded = dialog => func => {
    if (dialog) return func();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid>
        <ContainerBox>
          <List>
            <ListItemText
              primary={
                <Typography title="true">
                  Pedido <Chip label={data.id} />
                </Typography>
              }
            />
            <Box divider="true" />
            <Divider />
            <Box>
              <ListItemText
                primary={
                  <Typography title="true">Erro de integração:</Typography>
                }
                secondary={<Typography>{data.error_message}</Typography>}
              />
            </Box>
            <Box divider="true" />
            <Divider />
            <Box>
              <ListItemText
                primary={<Typography title="true">Detalhes</Typography>}
                secondary={
                  <ListContained>
                    {renderIfLoaded(open)(verifyHtml)}
                  </ListContained>
                }
              />
            </Box>
          </List>
        </ContainerBox>
      </Grid>
    </Dialog>
  );
}

IntegrationError.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
};
