import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedIndexSelectorAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({ entityName = '', search = '', parser = null }) {
    try {
      const { data } = await this.apiApp
        .get(`/${plural(entityName).replace('_', '-')}`, {
          params: search,
          for_selector: true,
        })
        .then(res => res.data);

      if (parser) return parser([{ ...data }])[0];

      return data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao buscar dados do ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'SharedIndexSelectorAppRepository',
      });

      throw errors;
    }
  }
}

export default SharedIndexSelectorAppRepository;
