import { Fragment, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import {
  ResultContainer,
  Icon,
  Tooltip,
  ListItem,
  ListItemText,
  Typography,
} from './styles';

import IntegrationError from '~/shared/components/Dialogs/IntegrationError';

const CustomRowOtherCells = ({ data, otherCells }) => {
  const [showDialog, setShowDialog] = useState(false);

  const otherCellsExclude = [];
  otherCells.forEach(otherCell => {
    if (otherCell.exclude) otherCellsExclude.push(otherCell.id);
  });

  const headCellsFinded = otherCells.filter(
    otherCell =>
      !otherCellsExclude.some(otherCellExclude =>
        otherCellExclude.includes(otherCell.id)
      )
  );

  return (
    <Fragment key={data.id}>
      <IntegrationError open={showDialog} setOpen={setShowDialog} data={data} />
      {otherCellsExclude.map(headCellExclude => (
        <TableCell key={headCellExclude} size="small">
          {data[`${headCellExclude}_icon`] === 'error' ? (
            <ResultContainer>
              <Tooltip
                title={data[`${headCellExclude}_status`] || 'Erro'}
                arrow
                onClick={() => setShowDialog(true)}
                placement="right"
              >
                <Icon status={data[`${headCellExclude}_icon`]}>
                  {data[`${headCellExclude}_icon`]}
                </Icon>
              </Tooltip>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography>
                      {data[`${headCellExclude}`] ||
                        data[`${headCellExclude}_status`]}
                    </Typography>
                  }
                  secondary={
                    <Tooltip arrow placement="top-start" title="Atualizado em">
                      <div>
                        {data[`${headCellExclude}_process_at`] ||
                          data[`${headCellExclude}_updated_at`]}
                      </div>
                    </Tooltip>
                  }
                />
              </ListItem>
            </ResultContainer>
          ) : (
            <ResultContainer>
              <Icon status={data[`${headCellExclude}_icon`]}>
                {data[`${headCellExclude}_icon`]}
              </Icon>
              <ListItem>
                {data[`${headCellExclude}_icon`] !== 'done' ? (
                  <ListItemText
                    primary={
                      <Typography>
                        {data[`${headCellExclude}_status`] ||
                          data[headCellExclude]}
                      </Typography>
                    }
                    secondary={
                      <Tooltip
                        arrow
                        placement="top-start"
                        title="Atualizado em"
                      >
                        <div>
                          {data[`${headCellExclude}_process_at`] ||
                            data[`${headCellExclude}_updated_at`]}
                        </div>
                      </Tooltip>
                    }
                  />
                ) : (
                  <ListItemText
                    primary={
                      <Typography>
                        {data[headCellExclude] ||
                          data[`${headCellExclude}_status`]}
                      </Typography>
                    }
                    secondary={
                      <Tooltip
                        arrow
                        placement="top-start"
                        title="Atualizado em"
                      >
                        <div>
                          {data[`${headCellExclude}_process_at`] ||
                            data[`${headCellExclude}_updated_at`]}
                        </div>
                      </Tooltip>
                    }
                  />
                )}
              </ListItem>
            </ResultContainer>
          )}
        </TableCell>
      ))}
      {headCellsFinded.map(headCellFinded => (
        <TableCell key={headCellFinded.id}>{data[headCellFinded.id]}</TableCell>
      ))}
    </Fragment>
  );
};

export default CustomRowOtherCells;

CustomRowOtherCells.propTypes = {
  data: PropTypes.shape().isRequired,
  otherCells: PropTypes.arrayOf().isRequired,
};
