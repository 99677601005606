export function sessionParse(accesses = [], branch_key = '') {
  if (!accesses || !accesses.length)
    return { current_accesses: {}, companies: [], branches: [] };

  const auxCompanies = [];
  const auxBranches = [];
  const current_accesses = { permissions: {}, roles: {} };

  delete accesses[0].image; // eslint-disable-line
  accesses[0].companies.forEach(company => {
    delete company.image; // eslint-disable-line
    const auxCompany = { ...company };

    delete auxCompany.branches;

    auxCompanies.push({ company_key: company.company_key, name: company.name });

    company.branches.forEach(branch => {
      delete branch.image; // eslint-disable-line
      const auxBranch = { ...branch };

      delete auxBranch.permissions;
      delete auxBranch.roles;

      if (branch_key && branch.branch_key === branch_key) {
        branch.permissions.forEach(permission => {
          current_accesses.permissions[permission.permission_key] = true;
        });

        branch.roles.forEach(role => {
          current_accesses.roles[role.role_key] = true;
        });
      }

      auxBranches.push({
        company_key: branch.company_key,
        branch_key: branch.branch_key,
        first_name: branch.first_name,
      });
    });
  });

  const companies = auxCompanies.filter(
    (v, i, a) => a.findIndex(t => t.company_key === v.company_key) === i
  );

  const branches = auxBranches.filter(
    (v, i, a) => a.findIndex(t => t.branch_key === v.branch_key) === i
  );

  return { current_accesses, companies, branches };
}

export const formDataParser = formData => {
  return {
    email: formData.email,
    password: formData.password,
    branch_key: formData.branch_key,
    company_key: formData.company_key,
  };
};

export const formDataParseRecover = formData => {
  return {
    email: formData.email,
  };
};

export const formDataParserUpdatePassword = formData => {
  return {
    password: formData.password,
    password_confirmation: formData.password_confirmation,
  };
};
