import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import AppTheme from './providers/theme';
import Routes from './routes';
import { GlobalStyle, ToastContainer } from './styles/global';
import { HooksProvider } from './providers/hooks';
import ErrorHandler from './errors/ErrorHandler';

import 'react-toastify/dist/ReactToastify.min.css';
import 'date-fns';

export const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <BrowserRouter>
        <HooksProvider>
          <AppTheme>
            <Routes />
            <GlobalStyle />
            <ToastContainer />
            <ErrorHandler />
          </AppTheme>
        </HooksProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
};
