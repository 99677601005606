/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Group } from '@material-ui/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import { useSession } from '../../hooks/useSession';
import validatorSchema from '../../validators/SignIn';
import { formDataParser } from '../../sanitizers/session';
import {
  ChangeBranchContainer,
  ChangeBranchLinkContainer,
  LoginItems,
  Avatar,
  Typography,
  Button,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  Link,
} from './styles';

import { Form, Loading, Selector } from '~/shared/components/Form';

function Branch({ setIndex }) {
  const {
    sessionErrors,
    sessionLoading,
    branches,
    changeBranch,
    companies,
    signed,
    email,
    password,
    signIn,
    signOut,
  } = useSession();

  const formRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const [branchesOfSelectedCompany, setBranchesOfSelectedCompany] = useState(
    []
  );

  const handleSubmit = async ({ branch_key }) => {
    if (signed) {
      await changeBranch({ branch_key });
      history.goBack();
    } else await signIn({ email, password, branch_key });
  };

  function handleSelectCompany(company) {
    if (company?.company_key) {
      const auxBranchesOfSelectedCompany = branches.filter(
        branch => branch.company_key === company.company_key
      );

      setBranchesOfSelectedCompany(auxBranchesOfSelectedCompany);
    } else {
      const branchKeyInputRef = formRef.current.getFieldRef('branch_key');
      branchKeyInputRef.handleChange(null);
      setBranchesOfSelectedCompany([]);
    }
  }

  useEffect(() => {
    if (
      Object.keys(sessionErrors).length &&
      sessionErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(sessionErrors.validations);
    else {
      setBranchesOfSelectedCompany([]);
    }
  }, [sessionErrors]);

  return (
    <ChangeBranchContainer location={location.pathname}>
      <LoginItems>
        <Avatar>
          <Group />
        </Avatar>
        <Typography>Selecionar Empresa</Typography>
        <Form
          formRef={formRef}
          onSubmit={handleSubmit}
          validatorSchema={validatorSchema}
          dataParser={formDataParser}
        >
          <Selector
            name="company_key"
            label="Selecione alguma Empresa"
            idColumn="company_key"
            nameColumn="name"
            onChange={handleSelectCompany}
            options={companies}
            loading={sessionLoading}
          />
          <Selector
            name="branch_key"
            label="Selecionar Filial"
            idColumn="branch_key"
            nameColumn="first_name"
            options={branchesOfSelectedCompany}
            loading={sessionLoading}
          />

          <Button type="submit" disabled={sessionLoading}>
            {sessionLoading && <Loading />}
            Acessar
          </Button>
        </Form>
        <ForgotPasswordContainer>
          <ForgotPasswordContent>
            {location.pathname !== '/changebranch' && (
              <Link
                onClick={() => {
                  setIndex(1);
                  signOut();
                }}
              >
                <p>Voltar para a tela de Login</p>
              </Link>
            )}
            {location.pathname === '/changebranch' && (
              <Link onClick={() => history.goBack()}>
                <ChangeBranchLinkContainer>
                  <Icon>chevron_left</Icon>
                  <p>Voltar</p>
                </ChangeBranchLinkContainer>
              </Link>
            )}
          </ForgotPasswordContent>
        </ForgotPasswordContainer>
      </LoginItems>
    </ChangeBranchContainer>
  );
}

export default Branch;

Branch.propTypes = {
  setIndex: PropTypes.func,
};

Branch.defaultProps = {
  setIndex: null,
};
