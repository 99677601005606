import {
  Route,
  useLocation,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSession } from '~/modules/sessions/hooks/useSession';

import SalesOrdersList from '../pages/List';

const SalesOrdersRoute = () => {
  const location = useLocation();
  const { path } = useRouteMatch();

  const { current_accesses } = useSession();

  if (
    current_accesses.roles['farm.admin'] ||
    (current_accesses.roles['farm.manager'] &&
      current_accesses.permissions['farm.sales-orders.all']) ||
    (current_accesses.permissions['farm.sales-orders.get'] &&
      current_accesses.permissions['farm.sales-orders.all'])
  ) {
    return (
      <Switch>
        <Route path={path} exact component={SalesOrdersList} />
        <Route path="*">
          <Redirect to={path} />
        </Route>
      </Switch>
    );
  }

  if (location.pathname === '/sales-orders') {
    toast.warning(
      'Usuário não tem permissão para acessar a página de Pedido de Venda'
    );
    return <Redirect to="/" />;
  }

  return <></>;
};

export default SalesOrdersRoute;
