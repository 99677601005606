import { format, parseISO } from 'date-fns';
import { valueMask, cpfMask, cnpjMask } from '~/shared/providers/utils/mask';

import { parseIntegrationArrayValidations } from '~/shared/errors/handler';

export function parseToView(salesOrders = []) {
  return salesOrders.map(salesOrder => {
    const erp_id_created_at =
      salesOrder.erp_created_at !== null
        ? format(parseISO(salesOrder.erp_created_at), "dd'/'MM'/'yyyy - HH:mm")
        : null;

    const erp_id_updated_at =
      salesOrder.erp_updated_at !== null
        ? format(parseISO(salesOrder.erp_updated_at), "dd'/'MM'/'yyyy - HH:mm")
        : null;

    const ecommerce_id_created_at =
      salesOrder.ecommerce_created_at !== null
        ? format(
            parseISO(salesOrder.ecommerce_created_at),
            "dd'/'MM'/'yyyy - HH:mm"
          )
        : null;

    const ecommerce_id_updated_at =
      salesOrder.ecommerce_updated_at !== null
        ? format(
            parseISO(salesOrder.ecommerce_updated_at),
            "dd'/'MM'/'yyyy - HH:mm"
          )
        : null;

    const erp_id_process_at = salesOrder.erp_process_at
      ? format(parseISO(salesOrder.erp_process_at), "dd'/'MM'/'yyyy - HH:mm")
      : '';

    const ecommerce_id_process_at = salesOrder.ecommerce_process_at
      ? format(
          parseISO(salesOrder.ecommerce_process_at),
          "dd'/'MM'/'yyyy - HH:mm"
        )
      : null;

    const id_updated_at = salesOrder.updated_at
      ? format(parseISO(salesOrder.updated_at), "dd'/'MM'/'yyyy - HH:mm")
      : null;

    const customer_erp_id_process_at = salesOrder.customer_erp_process_at
      ? format(
          parseISO(salesOrder.customer_erp_process_at),
          "dd'/'MM'/'yyyy - HH:mm"
        )
      : null;

    const customer_ecommerce_id_process_at = salesOrder.customer_ecommerce_process_at
      ? format(
          parseISO(salesOrder.customer_ecommerce_process_at),
          "dd'/'MM'/'yyyy - HH:mm"
        )
      : null;

    const purchase_order_ecommerce_id_process_at = salesOrder.purchase_order_ecommerce_process_at
      ? format(
          parseISO(salesOrder.purchase_order_ecommerce_process_at),
          "dd'/'MM'/'yyyy - HH:mm"
        )
      : null;

    const purchase_order_erp_id_process_at = salesOrder.purchase_order_erp_process_at
      ? format(
          parseISO(salesOrder.purchase_order_erp_process_at),
          "dd'/'MM'/'yyyy - HH:mm"
        )
      : null;

    let invoice_process_at = null;

    if (salesOrder.invoice_erp_process_at) {
      invoice_process_at = format(
        parseISO(salesOrder.invoice_erp_process_at),
        "dd'/'MM'/'yyyy - HH:mm"
      );
    } else if (salesOrder.invoice_ecommerce_process_at) {
      invoice_process_at = format(
        parseISO(salesOrder.invoice_ecommerce_process_at),
        "dd'/'MM'/'yyyy - HH:mm"
      );
    }

    let error_message = null;
    let error_message_details = null;
    let attempts_view = salesOrder.attempts;

    if (attempts_view === null) attempts_view = 0;

    try {
      try {
        const error = null;
        error_message = JSON.parse(salesOrder?.error_message)?.message;
        error_message_details = JSON.parse(salesOrder?.error_message)?.details;

        if (
          !error_message_details &&
          JSON.parse(salesOrder?.error_message).validations
        )
          throw error;
      } catch (error) {
        error_message = JSON.parse(salesOrder?.error_message)?.message;

        const arrToValidate = JSON.parse(salesOrder?.error_message)
          ?.validations;

        error_message_details = parseIntegrationArrayValidations(arrToValidate);
      }
    } catch (error) {
      error_message = salesOrder?.error_message;
    }

    let ecommerce_id_icon = 'refresh';
    let ecommerce_id_status = 'Aguardando preenchimento';

    let erp_id_icon = 'refresh';
    let erp_id_status = 'Aguardando preenchimento';

    let id_icon = 'refresh';
    let id_status = 'Código FARM não preenchido';

    let customer_ecommerce_id_icon = 'refresh';
    let customer_ecommerce_id_status = 'Aguardando preenchimento';

    let customer_erp_id_icon = 'refresh';
    let customer_erp_id_status = 'Aguardando preenchimento';

    let purchase_order_ecommerce_id_icon = 'refresh';
    let purchase_order_ecommerce_id_status = 'Aguardando preenchimento';

    let purchase_order_erp_id_icon = 'refresh';
    let purchase_order_erp_id_status = 'Aguardando preenchimento';

    let invoice_icon = 'refresh';
    let invoice_status = 'Aguardando preenchimento';

    let customer_first_name = '';
    let customer_cpf_cnpj = '';

    if (salesOrder.ecommerce_id) {
      ecommerce_id_icon = 'done';
      ecommerce_id_status = 'Código E-commerce integrado com sucesso';
    }

    if (salesOrder.erp_id) {
      erp_id_icon = 'done';
      erp_id_status = 'ERP ID integrado com sucesso';
    }

    if (salesOrder.customer_ecommerce_id) {
      customer_ecommerce_id_icon = 'done';
      customer_ecommerce_id_status =
        'Código Cliente E-commerce integrado com sucesso';
    }

    if (salesOrder.customer_erp_id) {
      customer_erp_id_icon = 'done';
      customer_erp_id_status = 'Código Cliente ERP integrado com sucesso';
    }

    if (salesOrder.purchase_order_ecommerce_id) {
      purchase_order_ecommerce_id_icon = 'done';
      purchase_order_ecommerce_id_status =
        salesOrder.purchase_order_ecommerce_id;
    }

    if (salesOrder.purchase_order_erp_id) {
      purchase_order_erp_id_icon = 'done';
      purchase_order_erp_id_status = salesOrder.purchase_order_erp_id;
    }

    if (salesOrder.invoice) {
      invoice_icon = 'done';
      invoice_status = salesOrder.invoice;
    }

    if (salesOrder.error_message) {
      id_icon = 'error';
      id_status = error_message;
    } else if (salesOrder.id) {
      id_icon = 'done';
    }

    if (salesOrder?.customer?.uuid) {
      customer_first_name = salesOrder.customer.first_name;
      customer_cpf_cnpj =
        salesOrder.customer.type === 'J'
          ? cnpjMask(salesOrder.customer.cpf_cnpj)
          : cpfMask(salesOrder.customer.cpf_cnpj);
    }

    return {
      ...salesOrder,
      ecommerce_id_icon,
      ecommerce_id_status,
      customer_ecommerce_id_status,
      customer_erp_id_process_at,
      customer_ecommerce_id_process_at,
      purchase_order_ecommerce_id_icon,
      purchase_order_ecommerce_id_status,
      purchase_order_erp_id_icon,
      purchase_order_erp_id_status,
      purchase_order_ecommerce_id_process_at,
      purchase_order_erp_id_process_at,
      customer_erp_id_status,
      customer_erp_id_icon,
      erp_id_icon,
      erp_id_status,
      customer_ecommerce_id_icon,
      total_value: valueMask(salesOrder.total_value),
      subtotal_value: valueMask(salesOrder.subtotal_value),
      shipping_value: valueMask(salesOrder.shipping_value),
      error_message,
      error_message_details,
      attempts_view,
      erp_id_created_at,
      erp_id_updated_at,
      ecommerce_id_created_at,
      ecommerce_id_updated_at,
      erp_id_process_at,
      ecommerce_id_process_at,
      id_updated_at,
      id_status,
      id_icon,
      customer_first_name,
      customer_cpf_cnpj,
      invoice_icon,
      invoice_status,
      invoice_process_at,
    };
  });
}

export function parseDataToDestroy(salesOrder = {}) {
  return {
    data: [
      {
        req_id: salesOrder.req_id,
        uuid: salesOrder.uuid,
        company_key: salesOrder.company_key,
        branch_key: salesOrder.branch_key,
      },
    ],
  };
}

export const formDataParser = formData => {
  return {
    type: formData.type,
    indicator: formData.indicator,
    start_at: formData.start_at,
  };
};

export const formUnitaryIntegrationParser = formData => {
  return {
    entity: formData.entity,
    origin: formData.origin,
  };
};
