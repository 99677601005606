import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedListAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({
    entityName = '',
    search = '',
    order_by = '',
    order = '',
    filter_by = '',
    page = 1,
    parser = null,
  }) {
    try {
      const { total, data } = await this.apiApp
        .get(`/${plural(entityName).replace('_', '-')}`, {
          params: { search, order_by, order, page, filter_by },
        })
        .then(res => res.data);

      if (parser) return { total, data: parser(data) };

      return { total, data };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao listar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `BaseRepositoriesAppIndex`,
      });

      throw errors;
    }
  }
}

export default SharedListAppRepository;
