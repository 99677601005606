/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  CodeAuthPassContainer,
  CodeAuth,
  ForgotPasswordContainer,
  ForgotPasswordContent,
  Link,
} from './styles';

function ForgotPasswordPage({ setIndex }) {
  const [codeAuthLoad, setCodeAuthLoaded] = useState(false);
  const [appKey, setAppKey] = useState('');

  useEffect(() => {
    if (codeAuthLoad) setAppKey(process.env.REACT_APP_APPLICATION_KEY);
  }, [codeAuthLoad]);

  const handleIframeToast = useCallback(
    event => {
      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'codeAuthLoaded'
      ) {
        setCodeAuthLoaded(true);
      }

      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendToastValidationError'
      )
        toast.warn('Ocorreu um erro de validação');

      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendToastNoEmailFound'
      ) {
        toast.warn('Email não encontrado');
      }

      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendToastValidationSuccess'
      ) {
        setIndex(1);
        toast.success('E-mail enviado com sucesso');
      }
      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'errorForgotPassword'
      ) {
        toast.warn('Ocorreu um erro ao se conectar com o Code Auth');
      }
    },
    [setIndex]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeToast);
    return () => window.removeEventListener('message', handleIframeToast);
  }, [handleIframeToast]);

  return (
    <CodeAuth>
      <CodeAuthPassContainer
        onLoad={() => handleIframeToast}
        src={`${process.env.REACT_APP_CODE_AUTH_URL}/forgot-password/?application_key=${appKey}&theme=light&url=${process.env.REACT_APP_APP_URL}`}
        width="100%"
        title="Recuperar senha do Code Auth"
      />

      <ForgotPasswordContainer>
        <ForgotPasswordContent>
          <Link onClick={() => setIndex(1)}>
            <p>Voltar para a tela de Login</p>
          </Link>
        </ForgotPasswordContent>
      </ForgotPasswordContainer>
    </CodeAuth>
  );
}

export default ForgotPasswordPage;

ForgotPasswordPage.propTypes = {
  setIndex: PropTypes.func,
};

ForgotPasswordPage.defaultProps = {
  setIndex: null,
};
