import { useState } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';

import {
  ContentInputFilter,
  FomControlFilterType,
  InputLabel,
  Select,
  MenuItem,
  FomControlInputDate,
} from './styles';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '200%',
  },
  closeButton: {
    position: 'absolute',
  },
}));

function TableListToolbar({
  title,
  newItem,
  searchRef,
  handleChangeSearchText,
  canEdit,
  filtersOptions,
  idFilterInitial,
}) {
  const classes = useToolbarStyles();

  const [filterSelected, setFilterSelected] = useState(() => {
    return filtersOptions.find(item => item.id === idFilterInitial) || {};
  });
  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterButton = filter_by => {
    const parsedFilterBy = filter_by || filterSelected;

    if (parsedFilterBy.type === 'date') {
      if (!initialDate || !endDate) {
        toast.warning('Preencha com datas válidas para realizar o filtro.');

        return;
      }
      const filterDate = `${initialDate};${endDate}`;

      handleChangeSearchText({
        filter_by: parsedFilterBy.id,
        text: filterDate,
      });

      return;
    }

    handleChangeSearchText({
      filter_by: parsedFilterBy.id,
      text: (searchRef.current && searchRef.current.value) || '',
    });
  };

  const handleClearFilterButton = () => {
    handleChangeSearchText({
      filter_by: idFilterInitial,
      text: '',
    });

    setFilterSelected(filtersOptions.find(item => item.id === idFilterInitial));
  };

  const handleChangeText = event => {
    handleChangeSearchText({
      filter_by: filterSelected.id,
      text: event.target.value,
    });
  };

  const handleInitialDate = date => {
    setInitialDate(date && !isNaN(date) ? date.toISOString() : null); // eslint-disable-line
  };

  const handleEndDate = date => {
    setEndDate(date && !isNaN(date) ? date.toISOString() : null); // eslint-disable-line
  };

  const handleSelectedFilter = e => {
    setFilterSelected(e.target.value);
    handleFilterButton(e.target.value);
  };

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" component="div">
        {title}
        {newItem && (
          <Button
            variant="contained"
            color="primary"
            onClick={newItem.handleFunction}
            disabled={!canEdit}
            style={{ marginLeft: 10 }}
          >
            {newItem.label}
          </Button>
        )}
      </Typography>
      <ContentInputFilter>
        <FomControlFilterType variant="outlined" size="small">
          <InputLabel>Tipo de Filtro</InputLabel>
          <Select
            value={filterSelected}
            onChange={handleSelectedFilter}
            label="Tipo de Filtro"
          >
            {filtersOptions.map(filterOption => (
              <MenuItem key={filterOption.id} value={filterOption}>
                {filterOption.label}
              </MenuItem>
            ))}
          </Select>
        </FomControlFilterType>
        {filterSelected.type === 'string' ? (
          <TextField
            style={{ width: '100%' }}
            label="Buscar..."
            variant="outlined"
            inputRef={searchRef}
            size="small"
            type="search"
            onChange={handleChangeText}
          />
        ) : (
          <>
            <FomControlInputDate>
              <KeyboardDatePicker
                inputVariant="outlined"
                variant="inline"
                format="dd/MM/yyyy"
                label="De"
                value={initialDate}
                onChange={handleInitialDate}
                invalidDateMessage={null}
                size="small"
              />
            </FomControlInputDate>
            <FomControlInputDate>
              <KeyboardDatePicker
                inputVariant="outlined"
                variant="inline"
                format="dd/MM/yyyy"
                label="Até"
                value={endDate}
                onChange={handleEndDate}
                invalidDateMessage={null}
                size="small"
              />
            </FomControlInputDate>
          </>
        )}
      </ContentInputFilter>
      <Tooltip title="Limpar Filtro">
        <IconButton onClick={handleClearFilterButton}>
          <ClearIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Filtrar">
        <IconButton onClick={() => handleFilterButton()}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export const newItemPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  handleFunction: PropTypes.func.isRequired,
});

TableListToolbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  newItem: newItemPropType,
  searchRef: PropTypes.shape().isRequired,
  handleChangeSearchText: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  filtersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  idFilterInitial: PropTypes.string,
};

TableListToolbar.defaultProps = {
  newItem: null,
  canEdit: true,
  filtersOptions: [],
  idFilterInitial: '',
};

export default TableListToolbar;
