import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useSession } from '~/modules/sessions/hooks/useSession';
import { useParameter } from '../../hooks';

import WarningDialog from '~/shared/components/Dialogs/WarningDialog';
import TableList, {
  WrapperContainer,
} from '~/shared/components/TableList/TableList';

import { tableListOptions } from '../../constantVariables/parameter';

function List() {
  const tableListRef = useRef();
  const history = useHistory();

  const { current_accesses } = useSession();
  const { parameterListLoading, index, show, destroy } = useParameter();

  const [showWarningDialog, setShowWarnigDialog] = useState(false);
  const [parameterData, setParameterData] = useState({});

  const loadParameters = useCallback(
    async (
      search = '',
      order_by = 'id',
      order = 'asc',
      page = 1,
      filter_by = ''
    ) => {
      return index({ search, order_by, order, page, filter_by });
    },
    [index]
  );

  const newItem = useMemo(() => {
    if (current_accesses.roles['farm.admin']) {
      return {
        label: 'ADICIONAR',
        handleFunction: () =>
          history.push('/settings/parameters/form', { newParameter: true }),
      };
    }

    return null;
  }, [history, current_accesses]);

  const handleRemove = useCallback(async () => {
    const removedData = await destroy({
      dataObj: { ...parameterData, req_id: '1' },
    });

    if (removedData.uuid) {
      tableListRef.current.handleRemoveData({
        key: 'uuid',
        value: removedData,
      });
    }
    setShowWarnigDialog(false);
  }, [destroy, parameterData]);

  const actions = useMemo(() => {
    const auxActions = [];

    if (
      current_accesses.roles['farm.admin'] ||
      current_accesses.roles['farm.manager'] ||
      current_accesses.permissions['farm.parameters.get']
    ) {
      auxActions.push({
        label: 'Visualizar',
        icon: <VisibilityIcon fontSize="small" />,
        handleFunction: async auxParameter => {
          await show({ dataObj: auxParameter });
          history.push('/settings/parameters/form', { showParameter: true });
        },
      });
    }

    if (
      current_accesses.roles['farm.admin'] ||
      current_accesses.roles['farm.manager'] ||
      current_accesses.permissions['farm.parameters.put']
    ) {
      auxActions.push({
        label: 'Editar',
        icon: <EditIcon fontSize="small" />,
        handleFunction: async auxParameter => {
          await show({ dataObj: auxParameter });
          history.push('/settings/parameters/form', { updateParameter: true });
        },
      });
    }

    if (current_accesses.roles['farm.admin']) {
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: async auxParameter => {
          setParameterData(auxParameter);
          setShowWarnigDialog(true);
        },
      });
    }

    return auxActions;
  }, [history, show, current_accesses]);

  return (
    <WrapperContainer>
      <WarningDialog
        showDialog={showWarningDialog}
        setShowDialog={setShowWarnigDialog}
        description="Tem certeza que deseja remover o parâmetro?"
        onSuccess={handleRemove}
        loading={parameterListLoading}
      />
      <TableList
        title=""
        newItem={newItem}
        headCells={tableListOptions.headCells}
        loadData={loadParameters}
        loading={parameterListLoading}
        actions={actions}
        ref={tableListRef}
        filtersOptions={tableListOptions.filterOptions}
        idFilterInitial="name"
      />
    </WrapperContainer>
  );
}

export default List;
