import styled from 'styled-components';
import BoxStyled from '@material-ui/core/Box';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';
import TabsStyled from '@material-ui/core/Tabs';
import SwipeableViewsStyled from 'react-swipeable-views';

export { default as Divider } from '@material-ui/core/Divider';
export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as Stepper } from '@material-ui/core/Stepper';
export { default as Step } from '@material-ui/core/Step';
export { default as StepLabel } from '@material-ui/core/StepLabel';
export { default as Paper } from '@material-ui/core/Paper';
export { default as Tab } from '@material-ui/core/Tab';

export const SwipeableViews = styled(SwipeableViewsStyled)`
  height: calc(100vh - 158px);

  .react-swipeable-view-container {
    height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 110px);
`;

export const Tabs = styled(TabsStyled).attrs({})`
  &.MuiTabs-root {
    background: ${props => props.theme.colors.primaryText};
    color: ${props => props.theme.colors.secondary};
  }
`;

export const ActionsContainer = styled.div`
  padding: 0 2em;
`;
export const TitleBox = styled(BoxStyled).attrs({
  mt: 3,
})``;

export const Typography = styled(TypographyStyled).attrs({
  component: 'h2',
  variant: 'h4',
  color: 'textPrimary',
})`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const TypographyDescription = styled(TypographyStyled).attrs({
  component: 'p',
  variant: 'overline',
  color: 'textSecondary',
})``;

export const SettingsContent = styled(ButtonStyled).attrs({
  color: 'primary',
  size: 'large',
  variant: 'outlined',
})`
  &.MuiButton-root {
    padding: 2em 5em;
    max-width: 200px;
  }
`;

export const CodeAuth = styled.iframe`
  width: 100%;
  height: calc(100vh - 160px);
  border: 0;
`;
