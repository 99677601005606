import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedReprocessAllAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({ entityName = '', type = '', indicator = '', start_at = '' }) {
    try {
      const { data } = await this.apiApp
        .patch(
          plural(entityName).replace('_', '-'),
          {},
          { params: { start_at, indicator, type } }
        )
        .then(res => res.data);

      return data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao reprocessar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `SharedReprocessAllAppRepository`,
      });

      throw errors;
    }
  }
}

export default SharedReprocessAllAppRepository;
