import { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useSession } from '~/modules/sessions/hooks/useSession';

import { useParameter, useIndicator } from '../../hooks';
import validatorSchema from '../../validators/parameter';

import {
  Form,
  TextInput,
  Chip,
  Loading,
  Selector,
} from '~/shared/components/Form';
import { parseDataToStoreOrUpdate } from '../../sanitizers/parameter';

import {
  Container,
  InputSpacingGrid,
  FieldGrid,
  TitleBox,
  Box,
  Typography,
  TypographyDescription,
  Divider,
  FormularyBoxDivider,
  Button,
  List,
} from './styles';

const Formulary = () => {
  const formRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const {
    parameter,
    clearState,
    store,
    update,
    parameterErrors,
    parameterLoading,
    formData,
  } = useParameter();

  const { logged_branch } = useSession();
  const { indexSelector: indicatorIndexSelector } = useIndicator();

  const [indicators, setIndicators] = useState([]);
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const loadIndicators = useCallback(
    async (search = '') => {
      setLoadingIndicators(true);
      const auxCustomerGroups = await indicatorIndexSelector({ search });
      setLoadingIndicators(false);

      setIndicators(auxCustomerGroups);
    },
    [indicatorIndexSelector]
  );

  async function handleSubmit(data) {
    try {
      const parsedData = {
        ...data,
        branch_key: logged_branch.branch_key,
        company_key: logged_branch.company_key,
      };

      if (parameter.uuid) await update({ dataObj: parsedData });
      else await store({ dataObj: parsedData });
    } catch (error) {
      if (error?.error?.validations)
        formRef.current.setErrors(error?.error?.validations);
      else if (error?.validations) {
        formRef.current.setErrors(error?.validations);
      }
    }
  }

  const loadFormDataParameter = useCallback(async () => {
    const { indicators: auxIndicators } = await formData();

    setIndicators(auxIndicators);
  }, [formData]);

  useEffect(() => {
    if (parameter.uuid) {
      const { indicators: auxIndicators } = parameter;
      setIndicators(auxIndicators);

      const findIndicator = auxIndicators.find(
        indicator => indicator.uuid === parameter.indicator
      );
      const indicatorInputRef = formRef.current.getFieldRef('indicator');
      indicatorInputRef?.handleChange(findIndicator);
      if (location.state && !location.state.showParameter) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newParameter) {
      loadFormDataParameter();
      setCanEdit(true);
    }
  }, [location, parameter, loadFormDataParameter]);

  useEffect(() => {
    if (
      Object.keys(parameterErrors).length &&
      parameterErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(parameterErrors.validations);
  }, [parameterErrors]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        dataParser={parseDataToStoreOrUpdate}
        validatorSchema={validatorSchema}
        customData={{
          company_key: parameter.uuid
            ? parameter.company_key
            : logged_branch.company_key,
          branch_key: parameter.uuid
            ? parameter.branch_key
            : logged_branch.branch_key,
        }}
        initialData={parameter}
      >
        <TitleBox>
          <Typography>
            Formulário de Parâmetros
            {parameter.uuid && <Chip name="uuid" label="uuid" disabled />}
          </Typography>
          <TypographyDescription>
            Preencha os campos abaixo:
          </TypographyDescription>
        </TitleBox>
        <TitleBox>
          <Divider />
        </TitleBox>
        <Box>
          <List>
            <FieldGrid container>
              <TypographyDescription>Dados do Parâmetro</TypographyDescription>
            </FieldGrid>
            <FieldGrid container>
              <InputSpacingGrid container>
                <TextInput
                  style={{ width: '500px' }}
                  name="name"
                  label="Nome"
                  disabled={!canEdit}
                  required
                />
                <TextInput
                  style={{ width: '500px' }}
                  name="description"
                  label="Descrição"
                  disabled={!canEdit}
                />

                <TextInput
                  style={{ width: '500px' }}
                  required
                  name="key"
                  label="Chave"
                  disabled={!canEdit}
                />
                <Selector
                  style={{ width: '200px' }}
                  name="indicator"
                  label="Indicador"
                  idColumn="uuid"
                  nameColumn="name"
                  loadData={loadIndicators}
                  loading={loadingIndicators}
                  options={indicators}
                  disabled={!canEdit}
                  required
                />
                <TextInput
                  name="value"
                  label="Valor"
                  disabled={!canEdit}
                  required
                />
              </InputSpacingGrid>
            </FieldGrid>
          </List>
          <FormularyBoxDivider />
          <Divider />
          <FormularyBoxDivider />
          <FormularyBoxDivider />
          <FieldGrid container>
            <InputSpacingGrid container item>
              <Button
                cancel="cancel"
                onClick={() => history.goBack()}
                disabled={parameterLoading || !canEdit}
              >
                {parameterLoading && <Loading />} Cancelar
              </Button>
              <Button
                type="submit"
                confirm="confirm"
                disabled={parameterLoading || !canEdit}
              >
                {parameterLoading && <Loading />} Confirmar
              </Button>
            </InputSpacingGrid>
          </FieldGrid>
        </Box>
      </Form>
    </Container>
  );
};

export default Formulary;
