import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';

import { Error as ErrorIcon, Help } from '@material-ui/icons';

import { Error } from './styles';

function Checkbox({ name, label, helper, required, checkedInput, ...rest }) {
  const { fieldName, registerField, error, clearError } = useField(name);

  const inputRef = useRef({ checked: false });

  const [checked, setChecked] = useState(checkedInput || false);

  const handleChange = useCallback(
    event => {
      setChecked(event.target.checked);

      if (error) clearError();
    },
    [error, clearError]
  );

  useEffect(() => {
    inputRef.current.handleChange = handleChange;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, handleChange, registerField]);

  return (
    <FormControl component="fieldset" error={!!error}>
      <FormControlLabel
        label={
          <p>
            {label} {required && <b style={{ color: '#d50000' }}>*</b>}
          </p>
        }
        control={
          <MUICheckbox
            inputRef={inputRef}
            checked={checked}
            onChange={handleChange}
            color="primary"
          />
        }
        labelPlacement="end"
        {...rest}
      />

      {helper && (
        <Error type="help" title={helper}>
          <Help size={20} color="primary" />
        </Error>
      )}

      {error && (
        <Error title={error}>
          <ErrorIcon size={20} color="error" />
        </Error>
      )}
    </FormControl>
  );
}

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  helper: PropTypes.string,
  checkedInput: PropTypes.bool,
};

Checkbox.defaultProps = {
  required: false,
  checkedInput: false,
  helper: '',
};
