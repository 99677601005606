import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import { sessionParse } from '~/modules/sessions/sanitizers/session';

class SessionAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async store({ email = '', password = '', branch_key = '', force = false }) {
    try {
      const response = await this.apiApp.post(
        '/sessions',
        {
          email,
          password,
          branch_key,
        },
        {
          headers: {
            force,
            'Application-Keys': process.env.REACT_APP_APPLICATION_KEY,
          },
        }
      );

      if (response.status !== 200) {
        const { error } = response.data;

        throw error;
      }

      const { data } = response;

      if (data.token) {
        apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
        apiApp.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;
      }

      const sessionParsed = sessionParse(data.accesses, branch_key);

      if (branch_key) {
        delete sessionParsed.companies;
        delete sessionParsed.branches;
      }

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Error tentar ao entrar',
        local: 'SessionAppRepositoryStore',
      });

      throw errors;
    }
  }

  async changeBranch({ branch_key = '', force = false }) {
    try {
      const { data } = await this.apiApp.post(
        '/change-branches',
        {
          branch_key,
        },
        {
          headers: { force },
        }
      );
      apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
      apiApp.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;

      const sessionParsed = sessionParse(data.accesses, branch_key);
      delete sessionParsed.companies;
      delete sessionParsed.branches;

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Error tentar ao mudar Empresa',
        local: 'SessionAppRepositoryChangeBranch',
      });

      throw errors;
    }
  }

  async refreshToken() {
    try {
      const { data } = await this.apiApp.patch('/sessions');

      apiApp.defaults.headers.authorization = `Bearer ${data.token.token}`;
      apiApp.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;

      const sessionParsed = sessionParse(
        data.accesses,
        data.logged_branch.branch_key
      );
      delete sessionParsed.companies;
      delete sessionParsed.branches;

      return { ...data, ...sessionParsed };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Error tentar ao Empresa',
        local: 'SessionAppRepositoryRefreshToken',
      });

      throw errors;
    }
  }
}

export default SessionAppRepository;
