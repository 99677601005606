import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import ParametersList from '../pages/List';
import ParametersForm from '../pages/Form';

const ParametersRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={ParametersList} />
      <Route path={`${path}/form`} exact component={ParametersForm} />
      <Route path="*">
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default ParametersRoute;
