import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import { SharedGenerateJSONAppRepository } from '../repositories/app';

class SharedGenerateJSONAppService {
  constructor() {
    this.sharedGenerateJSONAppRepository = new SharedGenerateJSONAppRepository();
  }

  async execute({ entityName, indicator, row, origin }) {
    try {
      await this.sharedGenerateJSONAppRepository.execute({
        entityName,
        row,
        origin,
        indicator,
      });

      return {};
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao gerar JSON do ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'SharedGenerateJSONAppService',
      };

      throw errors;
    }
  }
}

export default SharedGenerateJSONAppService;
