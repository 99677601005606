import { useSession } from '~/modules/sessions/hooks/useSession';
import Signed from './Signed';
import NotSigned from './NotSigned';

const Routes = () => {
  const { signed } = useSession();

  return signed ? <Signed /> : <NotSigned />;
};

export default Routes;
