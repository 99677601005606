import {
  Route,
  useLocation,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSession } from '~/modules/sessions/hooks/useSession';

import ProductsList from '../pages/List';

const ProductsRoute = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  const { current_accesses } = useSession();

  if (
    current_accesses.roles['farm.admin'] ||
    (current_accesses.roles['farm.manager'] &&
      current_accesses.permissions['farm.products.all']) ||
    (current_accesses.permissions['farm.products.get'] &&
      current_accesses.permissions['farm.products.all'])
  ) {
    return (
      <Switch>
        <Route exact path={path} component={ProductsList} />
        <Route path="*">
          <Redirect to={path} />
        </Route>
      </Switch>
    );
  }

  if (location.pathname === '/products') {
    toast.warning(
      'Usuário não tem permissão para acessar a página de Produtos'
    );
    return <Redirect to="/" />;
  }

  return <></>;
};

export default ProductsRoute;
