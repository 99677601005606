import React, { useEffect, useState } from 'react';
import { useSession } from '../../hooks/useSession';

import {
  Container,
  WelcomeContainer,
  WelcomeText,
  WelcomeCards,
  Card,
  CardIcon,
  CardInfo,
  Equalizer,
  ErrorIcon,
  CircularProgress,
  CardContent,
  CardList,
} from './styles';

function Dashboard() {
  const { user, dashboardInfo, sessionLoading } = useSession();

  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadDashboard() {
      const dashboardData = await dashboardInfo();
      setData(dashboardData);
    }

    loadDashboard();
  }, [dashboardInfo]);

  return (
    <Container>
      <WelcomeContainer>
        <WelcomeText>
          <h1>Olá, {user.name}! Seja bem vindo!</h1>
        </WelcomeText>
        <WelcomeText>
          <p>Suas atualizações.</p>
        </WelcomeText>
        <CardList>
          {data.length > 0
            ? data?.map(item => (
                <CardContent key={item.entity_name}>
                  <WelcomeText>
                    <h2>{item.friendly_entity_name}</h2>
                  </WelcomeText>
                  <WelcomeCards>
                    <Card type="month_total">
                      <CardIcon>
                        <Equalizer fontSize="large" />
                      </CardIcon>
                      <CardInfo>
                        {sessionLoading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <p>Total no Mês</p>
                            <h2>
                              <span>Quantidade</span> {item.by_month}
                            </h2>
                          </>
                        )}
                      </CardInfo>
                    </Card>
                    <Card type="year_total">
                      <CardIcon>
                        <Equalizer fontSize="large" />
                      </CardIcon>
                      <CardInfo>
                        {sessionLoading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <p>Total no Ano</p>
                            <h2>
                              <span>Quantidade</span> {item.by_year}
                            </h2>
                          </>
                        )}
                      </CardInfo>
                    </Card>
                    <Card type="error_total">
                      <CardIcon>
                        <ErrorIcon fontSize="large" />
                      </CardIcon>
                      <CardInfo>
                        {sessionLoading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <p>Total com Erros</p>
                            <h2>
                              <span>Quantidade</span> {item.with_error}
                            </h2>
                          </>
                        )}
                      </CardInfo>
                    </Card>
                  </WelcomeCards>
                </CardContent>
              ))
            : null}
        </CardList>
      </WelcomeContainer>
    </Container>
  );
}

export default Dashboard;
