export default function dictionaryEntity({ entityName }) {
  const dictionaryName = {
    product: 'Produto',
    products: 'Produtos',
    sales_order: 'Pedido de Venda',
    sales_orders: 'Pedidos de Vendas',
    parameter: 'Parâmetro',
    parameters: 'Parâmetros',
  };

  return dictionaryName[entityName];
}
