import apiApp from '~/config/apiApp';
import errorHandler from '~/shared/errors/handler';

class SessionDashboardAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute() {
    try {
      const { data } = await this.apiApp
        .get(`/dashboards`)
        .then(res => res.data);

      return data;
    } catch (error) {
      const errors = errorHandler({
        error,
        title: 'Erro ao buscar informações da Dashboard',
        local: `SessionDashboardAppRepository`,
      });

      throw errors;
    }
  }
}

export default SessionDashboardAppRepository;
