import { SharedShowAppRepository } from '../repositories/app';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedShowAppService {
  constructor() {
    this.sharedShowAppRepository = new SharedShowAppRepository();
  }

  async execute({ entityName = '', dataObj = {}, parser }) {
    try {
      const baseData = await this.sharedShowAppRepository.execute({
        entityName,
        dataObj,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao buscar ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: 'SharedShowAppServiceExecute',
      };

      throw errors;
    }
  }
}

export default SharedShowAppService;
