import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BoxStyled from '@material-ui/core/Box';
import ChevronLeftStyled from '@material-ui/icons/ChevronLeft';
import TypographyStyled from '@material-ui/core/Typography';
import { Button, ButtonBase } from '@material-ui/core';

import { motion } from 'framer-motion';

import AvatarImport from '@material-ui/core/Avatar';

export { default as Popper } from '@material-ui/core/Popper';
export { default as ClickAwayListener } from '@material-ui/core/ClickAwayListener';

export { default as ListItemAvatar } from '@material-ui/core/ListItemAvatar';
export { default as Divider } from '@material-ui/core/Divider';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as ListItem } from '@material-ui/core/ListItem';
export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as List } from '@material-ui/core/List';
export { default as CssBaseline } from '@material-ui/core/CssBaseline';
export { default as Typography } from '@material-ui/core/Typography';
export { default as MenuIcon } from '@material-ui/icons/Menu';
export { default as ArrowBackIosIcon } from '@material-ui/icons/ArrowBackIos';
export { default as DescriptionIcon } from '@material-ui/icons/Description';
export { default as PeopleAlt } from '@material-ui/icons/PeopleAlt';
export { default as Person } from '@material-ui/icons/Person';
export { default as Shop } from '@material-ui/icons/Shop';
export { default as Business } from '@material-ui/icons/Business';
export { default as BusinessCenter } from '@material-ui/icons/BusinessCenter';
export { default as SwapHoriz } from '@material-ui/icons/SwapHoriz';
export { default as ExpandMore } from '@material-ui/icons/ExpandMore';
export { default as Settings } from '@material-ui/icons/Settings';
export { default as clsx } from 'clsx';
export { default as DashboardIcon } from '@material-ui/icons/Dashboard';

export const ChevronLeft = styled(ChevronLeftStyled).attrs({
  fontSize: 'large',
})`
  color: ${props => props.theme.colors.secondaryText};
`;

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  popperRoot: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2000,
    width: 260,
    border: `1px solid #3D7FD9`,
    borderTop: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: 'primary',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  buttonStyledMenu: {
    padding: '0.6em',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    minWidth: '215px',
    justifyContent: 'flex-start',
    fontSize: '0.9rem;',
    '&:hover': { background: '#fff8' },
  },
  iconButtonStyled: {
    fontSize: '1.7rem',
    marginRight: '0.3em',
  },
}));

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Box = styled(BoxStyled).attrs({})``;

export const RouterBox = styled(BoxStyled).attrs({
  mt: 1,
})``;

export const Logo = styled.img`
  width: 100px;
  object-fit: contain;
`;

export const HeaderContent = styled(AppBar).attrs({
  position: 'fixed',
})`
  &.MuiPaper-root {
    background: ${props => props.theme.colors.secondary};
    flex-direction: row;
  }
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContent = styled(Toolbar)``;

export const FacileLogoContent = styled(Toolbar)``;

export const FacileWelcomeContent = styled(Button).attrs({
  variant: 'outlined',
  size: 'small',
})`
  &.MuiButton-root {
    min-width: 260px;
    max-width: 260px;
    height: 3.7em;
    margin-right: 2em;
    display: flex;
    border: ${props =>
      props.isopen !== 'open'
        ? `1px solid ${props.theme.colors.primary}`
        : `1px solid ${props.theme.colors.primary}`};
    border-bottom: ${props => (props.isopen === 'open' ? 'none' : '')};
    align-items: center;
    justify-content: space-between;
    border-radius: ${props =>
      props.isopen === 'open' ? '5px 5px 0px 0px' : '5px'};
    &:hover {
      background: ${props =>
        props.isopen === 'open'
          ? props.theme.colors.secondary
          : props.theme.colors.secondary};
    }
    span {
      color: ${props => props.theme.colors.primary};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const MenuContainer = styled(motion.div)`
  width: 260px;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.secondary};
`;

export const MenuActionContainer = styled(motion.div)``;

export const MenuUser = styled(ListItem)`
  &.MuiButtonBase-root {
    background: ${props => props.theme.colors.secondary};
    margin-bottom: 0.3em;
    &:hover {
      background: ${props => props.theme.colors.secondaryHover};
    }
  }
`;

export const MenuArrowContainer = styled(motion.div)`
  svg {
    color: ${props => props.theme.colors.primary};
  }
`;

export const MenuBranch = styled(motion.div)`
  color: ${props => props.theme.colors.backFirst};
  span {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    font-weight: bolder;
  }
  strong {
    color: ${props => props.theme.colors.primaryText};
    font-weight: lighter;
  }
`;

export const MenuItems = styled(ButtonBase)``;

export const AvatarImageContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    color: ${props => props.theme.colors.primaryText};
  }
`;

export const Avatar = styled(AvatarImport).attrs({})`
  &.MuiAvatar-root {
    width: 30px;
    height: 30px;
    margin-right: 0.4em;
  }
`;

export const MenuButton = styled(IconButton).attrs({
  edge: 'start',
  color: 'primary',
})``;

export const DrawerContent = styled(Drawer)`
  > .MuiPaper-root {
    background: ${props => props.theme.colors.secondary};
  }
`;

export const ListItemIconContent = styled(ListItemIcon)`
  > svg {
    color: ${props =>
      props.navigate === 'true'
        ? props.theme.colors.primaryText
        : props.theme.colors.primary};
    margin-left: 6px;
  }
`;
export const ListItemTitle = styled(ListItemText)`
  color: ${props => props.theme.colors.primaryText};
`;

export const Image = styled.img`
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Main = styled.main`
  padding: 0 !important;
`;

export const RouteContent = styled.div`
  display: flex;
  margin: 60px 0 0 0;
  height: 50px;
  align-items: center;
  justify-content: space-between;

  background: ${props => props.theme.colors.primary};
`;

export const RouteTitle = styled(TypographyStyled).attrs({
  variant: 'h5',
  component: 'h1',
  align: 'center',
})`
  color: ${props => props.theme.colors.secondary};
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: calc(100vh - 110px);
  background: ${props => props.theme.colors.secondaryText};
`;
