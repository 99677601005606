let canPress = true;
let time = null;

export const debounceClear = () => {
  canPress = true;
};

export const debouncePress = (auxTime = 1000) => {
  if (canPress) {
    canPress = false;
    setTimeout(() => debounceClear(), auxTime);
    return true;
  }
  return false;
};

export const debounceEvent = (fn, wait = 1000) => (...args) => {
  clearTimeout(time);
  time = setTimeout(() => fn(...args), wait);
};
