import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';

const DashboardRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={Dashboard} />
    </Switch>
  );
};

export default DashboardRoute;
