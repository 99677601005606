import { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
  SharedListAppService,
  SharedProcessAllAppService,
  SharedProcessUnitaryAppService,
  SharedDestroyAppService,
} from '~/shared/modules/services';

import { useErrorHandler } from '~/shared/errors/hook';

import { entityName } from '../constantVariables/product';
import { parseToView, parseDataToDestroy } from '../sanitizers/product';

const INITIAL_STATE = {
  productsListLoading: false,
};

const sharedListAppService = new SharedListAppService();
const sharedDestroyAppService = new SharedDestroyAppService();
const sharedProcessAllAppService = new SharedProcessAllAppService();
const sharedProcessUnitaryAppService = new SharedProcessUnitaryAppService();

const ProductsContext = createContext(INITIAL_STATE);
export function ProductsProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const [data, setData] = useState(INITIAL_STATE);

  const setProductsData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({
      search = '',
      order_by = '',
      order = '',
      page = 1,
      filter_by = '',
    }) => {
      setProductsData({ productsListLoading: true });

      let productsData = { total: 0, data: [] };

      try {
        productsData = await sharedListAppService.execute({
          entityName,
          search,
          order_by,
          order,
          page,
          filter_by,
          parser: parseToView,
        });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () =>
            index({ search, order_by, order, page, filter_by }),
        });
      }
      setProductsData({ productsListLoading: false });

      return productsData;
    },
    [setProductsData, setErrorHandlerData]
  );

  const reprocessAll = useCallback(
    async ({ type = '', indicator = '', start_at = '' }) => {
      try {
        setProductsData({ productsListLoading: true });

        const productsData = await sharedProcessAllAppService.execute({
          entityName,
          type,
          indicator,
          start_at,
        });

        setProductsData({ productsListLoading: false });

        toast.success(
          productsData?.message || 'O reprocessamento está em andamento...'
        );
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => reprocessAll({ type, indicator, start_at }),
        });

        setProductsData({ productsListLoading: false });
      }
    },
    [setProductsData, setErrorHandlerData]
  );

  const reprocessUnitary = useCallback(
    async ({ indicator, row, origin }) => {
      try {
        setProductsData({ productsListLoading: true });

        await sharedProcessUnitaryAppService.execute({
          entityName,
          indicator,
          row,
          origin,
        });

        setProductsData({ productsListLoading: false });
        toast.success(
          'Reprocesso unitário realizado com sucesso! Clique em FILTRAR para atualizar as informações'
        );
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => reprocessUnitary({ indicator, row, origin }),
        });

        setProductsData({ productsListLoading: false });
      }
    },
    [setProductsData, setErrorHandlerData]
  );

  const destroy = useCallback(
    async ({ dataObj = {} }) => {
      setProductsData({ productsListLoading: true });

      try {
        const productsData = await sharedDestroyAppService.execute({
          entityName,
          dataObj: parseDataToDestroy(dataObj),
        });

        toast.success('Pedido de Venda removido com sucesso!');

        setProductsData({ productsListLoading: false });
        return productsData;
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => destroy({ dataObj }),
        });
      }

      setProductsData({ productsListLoading: false });

      return {};
    },
    [setProductsData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        productsListLoading: false,
      };
    });
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        ...data,
        setProductsData,
        reprocessAll,
        reprocessUnitary,
        index,
        destroy,
        clearState,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export function useProduct() {
  const context = useContext(ProductsContext);

  if (!context)
    throw new Error('useProducts must be used within an ProductsProvider');

  return context;
}

ProductsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
