import styled, { css } from 'styled-components';

import IconStyled from '@material-ui/core/Icon';
import ListItemStyled from '@material-ui/core/ListItem';
import ListItemTextStyled from '@material-ui/core/ListItemText';
import ToolTipStyled from '@material-ui/core/Tooltip';
import TypographyStyled from '@material-ui/core/Typography';

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  gap: 5px;
`;

export const ListItem = styled(ListItemStyled).attrs({
  disableGutters: true,
})``;
export const ListItemText = styled(ListItemTextStyled).attrs({
  secondaryTypographyProps: {
    component: 'div',
  },
})``;

export const Typography = styled(TypographyStyled).attrs({
  variant: 'body2',
  component: 'span',
})``;

export const Icon = styled(IconStyled).attrs({})`
  color: ${props => {
    const colorDict = {
      refresh: props.theme.colors.refresh,
      error: props.theme.colors.error,
      done: props.theme.colors.passed,
    };

    return colorDict[props.status];
  }};
  ${props =>
    props.status === 'error' &&
    css`
      animation: pulse 1s infinite;
      cursor: pointer;
      transition: transform 200ms ease;
      &:hover {
        transform: scale(1.1);
      }
    `}

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(197, 48, 48, 1);
      border-radius: 50%;
    }
    70% {
      border-radius: 50%;
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0);
    }
  }
`;

export const Tooltip = styled(ToolTipStyled).attrs({})``;
