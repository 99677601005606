import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};

  background: white;
  border-radius: 4px;
  border: ${props => {
    if (!props.status) return 'none';

    let color = 'grey';

    if (props.status === 1) color = 'green';
    if (props.status === 2) color = 'yellow';

    return `2px solid ${color}`;
  }};

  img {
    width: 100%;
  }

  transition: transform 250ms ease;

  &:hover {
    opacity: 0.7;
    transform: scale(1.03);
  }
`;
