import { SessionDashboardAppRepository } from '../repositories/app';

class SessionDashboardAppService {
  constructor() {
    this.sessionDashboardAppRepository = new SessionDashboardAppRepository();
  }

  async execute() {
    try {
      const baseData = await this.sessionDashboardAppRepository.execute();

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao buscar informações da Dashboard`,
        local: 'SessionDashboardAppService',
      };

      throw errors;
    }
  }
}

export default SessionDashboardAppService;
