import React from 'react';
import PropTypes from 'prop-types';

import { ErrorHandlerProvider } from '../../errors/hook';
import { SessionProvider } from '~/modules/sessions/hooks/useSession';
import { ProductsProvider } from '~/modules/products/hooks';
import { SalesOrderProvider } from '~/modules/salesOrders/hooks';
import { ParameterProvider } from '~/modules/settings/parameters/hooks';

export function HooksProvider({ children }) {
  return (
    <ErrorHandlerProvider>
      <SessionProvider>
        <ParameterProvider>
          <ProductsProvider>
            <SalesOrderProvider>{children}</SalesOrderProvider>
          </ProductsProvider>
        </ParameterProvider>
      </SessionProvider>
    </ErrorHandlerProvider>
  );
}

HooksProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
