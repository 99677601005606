import { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
  SharedListAppService,
  SharedProcessAllAppService,
  SharedProcessUnitaryAppService,
  SharedGenerateJSONAppService,
  SharedDestroyAppService,
} from '~/shared/modules/services';

import { useErrorHandler } from '~/shared/errors/hook';
import { entityName } from '../constantVariables/salesOrder';
import { parseToView, parseDataToDestroy } from '../sanitizers/salesOrder';

const INITIAL_STATE = {
  listLoading: false,
};

const sharedListAppService = new SharedListAppService();
const sharedProcessAllAppService = new SharedProcessAllAppService();
const sharedProcessUnitaryAppService = new SharedProcessUnitaryAppService();
const sharedGenerateJSONAppService = new SharedGenerateJSONAppService();
const sharedDestroyAppService = new SharedDestroyAppService();

const SalesOrderContext = createContext(INITIAL_STATE);

export function SalesOrderProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const [data, setData] = useState(INITIAL_STATE);

  const setSalesOrdersData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({
      search = '',
      order_by = '',
      order = '',
      page = 1,
      filter_by = '',
    }) => {
      setSalesOrdersData({ listLoading: true });

      let salesOrdersData = { total: 0, data: [] };

      try {
        salesOrdersData = await sharedListAppService.execute({
          entityName,
          search,
          order_by,
          order,
          page,
          filter_by,
          parser: parseToView,
        });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () =>
            index({ search, order_by, order, page, filter_by }),
        });
      }
      setSalesOrdersData({ listLoading: false });

      return salesOrdersData;
    },
    [setSalesOrdersData, setErrorHandlerData]
  );

  const reprocessAll = useCallback(
    async ({ type = '', indicator = '', start_at = '' }) => {
      try {
        setSalesOrdersData({ listLoading: true });

        const salesOrdersData = await sharedProcessAllAppService.execute({
          entityName,
          type,
          indicator,
          start_at,
        });

        setSalesOrdersData({ listLoading: false });

        toast.success(
          salesOrdersData?.message || 'O reprocessamento está em andamento...'
        );
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => reprocessAll({ type, indicator, start_at }),
        });

        setSalesOrdersData({ listLoading: false });
      }
    },
    [setSalesOrdersData, setErrorHandlerData]
  );

  const reprocessUnitary = useCallback(
    async ({ indicator, row, origin }) => {
      try {
        setSalesOrdersData({ listLoading: true });

        await sharedProcessUnitaryAppService.execute({
          entityName,
          indicator,
          row,
          origin,
        });

        setSalesOrdersData({ listLoading: false });

        toast.success(
          'Reprocesso unitário realizado com sucesso! Clique em FILTRAR para atualizar as informações'
        );
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => reprocessUnitary({ indicator, row, origin }),
        });

        setSalesOrdersData({ listLoading: false });
      }
    },
    [setSalesOrdersData, setErrorHandlerData]
  );

  const generateJSON = useCallback(
    async ({ indicator, row, origin }) => {
      try {
        setSalesOrdersData({ listLoading: true });

        await sharedGenerateJSONAppService.execute({
          entityName,
          indicator,
          row,
          origin,
        });

        setSalesOrdersData({ listLoading: false });

        toast.success('JSON gerado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => generateJSON({ indicator, row, origin }),
        });

        setSalesOrdersData({ listLoading: false });
      }
    },
    [setSalesOrdersData, setErrorHandlerData]
  );

  const destroy = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setSalesOrdersData({ listLoading: true });

        const salesOrdersData = await sharedDestroyAppService.execute({
          entityName,
          dataObj: parseDataToDestroy(dataObj),
        });

        toast.success('Pedido de Venda removido com sucesso!');

        setSalesOrdersData({ listLoading: false });
        return salesOrdersData;
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => destroy({ dataObj }),
        });
        setSalesOrdersData({ listLoading: false });

        return {};
      }
    },
    [setSalesOrdersData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        listLoading: false,
      };
    });
  }, []);

  return (
    <SalesOrderContext.Provider
      value={{
        ...data,
        setSalesOrdersData,
        reprocessAll,
        reprocessUnitary,
        generateJSON,
        index,
        destroy,
        clearState,
      }}
    >
      {children}
    </SalesOrderContext.Provider>
  );
}

export function useSalesOrder() {
  const context = useContext(SalesOrderContext);

  if (!context)
    throw new Error('useSalesOrder must be used within an ProductsProvider');

  return context;
}

SalesOrderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
