import styled, { css } from 'styled-components';
import ContainerStyled from '@material-ui/core/Container';
import GridStyled from '@material-ui/core/Grid';
import BoxStyled from '@material-ui/core/Box';
import TypographyStyled from '@material-ui/core/Typography';
import ButtonStyled from '@material-ui/core/Button';
import ListMaterial from '@material-ui/core/List';

export { default as Divider } from '@material-ui/core/Divider';
export { default as Checkbox } from '@material-ui/core/Checkbox';

export const Container = styled(ContainerStyled).attrs({
  component: 'main',
  maxWidth: 'lg',
  spacing: 2,
  mt: 2,
  justify: 'start',
})``;
export const TitleBox = styled(BoxStyled).attrs({
  mt: 3,
})``;

export const Box = styled(BoxStyled).attrs({
  mt: 1,
})`
  ${props =>
    props.column === 'true' &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;
export const FieldGrid = styled(GridStyled).attrs(props => ({
  container: true,
  direction: props.column === 'true' ? 'column' : 'row',
}))`
  display: flex;
  align-items: center;
`;

export const InputSpacingGrid = styled(GridStyled).attrs(props => ({
  container: true,
  direction: props.column === 'true' ? 'column' : 'row',
}))`
  gap: 20px;
`;

export const InputGrid = styled(GridStyled).attrs({})``;

export const Typography = styled(TypographyStyled).attrs({
  component: 'h2',
  variant: 'h4',
  color: 'textPrimary',
})`
  align-items: center;
  display: flex;
  gap: 5px;
`;

export const TypographyDescription = styled(TypographyStyled).attrs({
  component: 'p',
  variant: 'overline',
  color: 'textSecondary',
})``;

export const FormularyBoxDivider = styled(BoxStyled).attrs({
  mt: 2,
})``;

export const Button = styled(ButtonStyled).attrs(props => ({
  variant: props.cancel ? 'outlined' : 'contained',
  color: 'primary',
}))`
  width: 115px;
  height: 40px;
`;

export const List = styled(ListMaterial).attrs({})`
  width: 100%;
  height: calc(70vh - 200px);
  overflow: auto;
`;
