import PropTypes from 'prop-types';

import TableBody from '@material-ui/core/TableBody';
import Row from './Row';

import { cellsPropType, selectPropType, actionsPropType } from './Head';

function TableListBody({
  rows,
  page,
  rowsPerPage,
  headCells,
  otherCells,
  observationCells,
  reprocessModal,
  reprocessFields,
  reprocessSubmit,
  generateJSON,
  data,
  actions,
  select,
  customRowHeadCells,
  customRowOtherCells,
}) {
  return (
    <TableBody>
      {rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(row => (
          <Row
            key={row.id}
            row={row}
            headCells={headCells}
            otherCells={otherCells}
            observationCells={observationCells}
            actions={actions}
            data={data}
            reprocessModal={reprocessModal}
            reprocessFields={reprocessFields}
            reprocessSubmit={reprocessSubmit}
            generateJSON={generateJSON}
            select={select}
            customRowHeadCells={customRowHeadCells}
            customRowOtherCells={customRowOtherCells}
          />
        ))}
    </TableBody>
  );
}

export default TableListBody;

TableListBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  customRowHeadCells: PropTypes.func,
  customRowOtherCells: PropTypes.func,
  observationCells: cellsPropType,
  actions: actionsPropType,
  reprocessModal: PropTypes.arrayOf(PropTypes.shape({})),
  reprocessFields: PropTypes.arrayOf(PropTypes.shape({})),
  reprocessSubmit: PropTypes.func,
  generateJSON: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  select: selectPropType,
};

TableListBody.defaultProps = {
  otherCells: null,
  customRowHeadCells: null,
  customRowOtherCells: null,
  observationCells: null,
  rows: [],
  data: [],
  actions: null,
  reprocessModal: [],
  reprocessFields: [],
  reprocessSubmit: null,
  generateJSON: null,
  select: null,
};
