import PropTypes from 'prop-types';

import yupValidator from '~/shared/providers/yup';

import { Container } from './styles';

function Form({
  formRef,
  dataParser,
  onSubmit,
  validatorSchema,
  customData,
  children,
  ...rest
}) {
  const handleSubmit = async formData => {
    const parsedFormData = { ...formData, ...customData };
    const { errorMessages, ...parsedData } = await yupValidator(
      parsedFormData,
      dataParser,
      validatorSchema
    );
    if (errorMessages) formRef?.current?.setErrors(errorMessages);
    else onSubmit(parsedData);
  };

  return (
    <Container ref={formRef} onSubmit={handleSubmit} {...rest}>
      {children}
    </Container>
  );
}

export default Form;

Form.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  validatorSchema: PropTypes.shape(),
  dataParser: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  customData: PropTypes.shape({}),
};

Form.defaultProps = {
  dataParser: null,
  validatorSchema: null,
  onSubmit: null,
  formRef: null,
  customData: null,
};
