import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';
import ImageContainer from '../ImageContainer';

import ReprocessUnitaryDialog from '~/shared/components/Dialogs/ReprocessUnitaryDialog';

import { cellsPropType, selectPropType, actionsPropType } from './Head';

import { ReprocessMenu } from './styles';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row({
  row,
  headCells,
  otherCells,
  observationCells,
  reprocessModal: originOptions,
  reprocessFields: indicatorOptions,
  reprocessSubmit,
  generateJSON,
  actions,
  select,
  customRowHeadCells,
  customRowOtherCells,
}) {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    if (actions.length <= 0) {
      toast.info('Usuário não tem permissão para acessar às AÇÕES.');
      setAnchorEl(null);

      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = action => {
    action.handleFunction(row);
    handleClose();
  };

  const handleSelectItem = () => {
    select.handleFunction(row);
  };

  const findRemoveButton = actions.find(action => action.label === 'Remover');

  const parsedRemoveAction = findRemoveButton && {
    func: handleSelectMenu,
    actionLabel: findRemoveButton,
  };

  return (
    <>
      <TableRow hover className={classes.root}>
        {otherCells ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
        {customRowHeadCells
          ? customRowHeadCells({ data: row, headCells })
          : headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
              >
                <div
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {headCell.image ? (
                    <ImageContainer
                      src={[row[headCell.id]]}
                      style={{ borderRadius: 4 }}
                    />
                  ) : (
                    row[headCell.id]
                  )}
                </div>
              </TableCell>
            ))}
        {select ? (
          <TableCell align="center">
            <IconButton onClick={handleSelectItem}>
              {row.icon || select.icon}
            </IconButton>
          </TableCell>
        ) : (
          actions && (
            <TableCell align="center">
              <IconButton onClick={handleOpenMenu}>
                <MenuOpenIcon />
              </IconButton>
              {reprocessSubmit ? (
                <ReprocessMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <ReprocessUnitaryDialog
                    open={open}
                    close={handleClose}
                    originOptions={originOptions}
                    indicatorOptions={indicatorOptions}
                    data={row}
                    removeAction={parsedRemoveAction}
                    generateJSONAction={generateJSON}
                    submitAction={reprocessSubmit}
                  />
                </ReprocessMenu>
              ) : (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {actions.map(action => (
                    <MenuItem
                      key={action.label}
                      onClick={() => handleSelectMenu(action)}
                      disabled={!!action.disabled}
                    >
                      {action.icon}
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </TableCell>
          )
        )}
      </TableRow>
      {otherCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (otherCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Outras Informações
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {otherCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {customRowOtherCells
                        ? customRowOtherCells({ data: row, otherCells })
                        : otherCells.map(otherCell => (
                            <TableCell
                              key={otherCell.id}
                              align={otherCell.numeric ? 'right' : 'left'}
                              padding={
                                otherCell.disablePadding ? 'none' : 'default'
                              }
                            >
                              <div
                                style={{
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {row[otherCell.id]}
                              </div>
                            </TableCell>
                          ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {observationCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (observationCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {observationCells.map(observationCell => (
                        <TableCell
                          key={observationCell.id}
                          align={observationCell.numeric ? 'right' : 'left'}
                          padding={
                            observationCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {observationCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {observationCells.map(observationCell => (
                        <TableCell
                          key={observationCell.id}
                          align={observationCell.numeric ? 'right' : 'left'}
                          padding={
                            observationCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          <div
                            style={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row[observationCell.id]}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default Row;

Row.propTypes = {
  row: PropTypes.shape().isRequired,
  reprocessModal: PropTypes.arrayOf(PropTypes.shape({})),
  reprocessFields: PropTypes.arrayOf(PropTypes.shape({})),
  reprocessSubmit: PropTypes.func,
  generateJSON: PropTypes.func,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  customRowHeadCells: PropTypes.func,
  customRowOtherCells: PropTypes.func,
  observationCells: cellsPropType,
  actions: actionsPropType,
  select: selectPropType,
};

Row.defaultProps = {
  otherCells: null,
  customRowHeadCells: null,
  customRowOtherCells: null,
  observationCells: null,
  reprocessModal: [],
  reprocessFields: [],
  reprocessSubmit: null,
  generateJSON: null,
  actions: null,
  select: null,
};
