export const entityName = 'parameter';

export const tableListOptions = {
  headCells: [
    { id: 'id', label: 'Código' },
    { id: 'name', label: 'Nome' },
    { id: 'description', label: 'Descrição' },
  ],
  filterOptions: [
    { id: 'id', label: 'Código', type: 'string' },
    { id: 'name', label: 'Nome', type: 'string' },
    { id: 'updated_at', label: 'Data processo', type: 'date' },
  ],
};
