import { Switch, Route } from 'react-router-dom';

import Content from '~/modules/sessions/pages/Content';
import RecoverPassword from '../pages/RecoverPassword';

const SessionsRoute = () => (
  <Switch>
    <Route path="/" exact component={Content} />
    <Route path="/recover-password" exact component={RecoverPassword} />
    <Route path="/profile" component={() => <h1>Profile</h1>} />
  </Switch>
);

export default SessionsRoute;
