import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Grid,
  Loading,
  Selector,
  DatePicker,
} from '~/shared/components/Form';

import { Dialog, Button, ListItemText } from './styles';

export default function ReprocessDialog({
  showDialog,
  setShowDialog,
  onSubmit,
  dataParser,
  validatorSchema,
  loading,
  typesOptions,
  indicatorOptions,
  dataInput,
}) {
  const formRef = useRef(null);

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog onClose={handleClose} open={showDialog}>
      <Form
        formRef={formRef}
        onSubmit={onSubmit}
        dataParser={dataParser}
        validatorSchema={validatorSchema}
      >
        <Grid>
          <ListItemText
            primary="Reprocessar Todos"
            secondary="Reprocesse todos os registros a partir do local de erro."
          />
          <Selector
            name="type"
            label="Tipo"
            idColumn="id"
            nameColumn="name"
            options={typesOptions}
            required
            helper="Selecione um tipo de reprocessamento "
          />
          <Selector
            name="indicator"
            label="Indicador"
            idColumn="id"
            nameColumn="name"
            options={indicatorOptions}
            required
            helper="Selecione um indicador para reprocessar"
          />

          {dataInput && (
            <DatePicker
              name="start_at"
              label="Data de início"
              helper="Informar a Data de início do reprocessamento. Padrão: Últimos 5 dias"
            />
          )}
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Confirmar
            {loading && <Loading color="secondary" />}
          </Button>
        </Grid>
      </Form>
    </Dialog>
  );
}

ReprocessDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dataParser: PropTypes.func.isRequired,
  validatorSchema: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  indicatorOptions: PropTypes.instanceOf(Array),
  typesOptions: PropTypes.instanceOf(Array),
  dataInput: PropTypes.bool,
};

ReprocessDialog.defaultProps = {
  validatorSchema: {},
  typesOptions: [],
  indicatorOptions: [],
  dataInput: false,
};
