/* eslint-disable consistent-return */
import { useEffect, useRef, useState, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { useSession } from '~/modules/sessions/hooks/useSession';

import { Form, RadioGroup } from '~/shared/components/Form';

import {
  Container,
  Divider,
  MenuHeadContainer,
  MenuHeadText,
  Chip,
  CheckBoxContainer,
  SelectorText,
  MenuButton,
  MenuButtonContainer,
  DeleteForever,
  ErrorOutline,
} from './styles';

function ReprocessUnitaryDialog(
  {
    close,
    data,
    originOptions,
    indicatorOptions,
    removeAction,
    generateJSONAction,
    submitAction,
  },
  ref
) {
  const formRef = useRef(null);
  const location = useLocation();
  const routeName = location.pathname.replace('/', '');

  const { current_accesses } = useSession();

  const [index, setIndex] = useState(0);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [reprocessConfirm, setReprocessConfirm] = useState(false);
  const [origin, setOrigin] = useState('');
  const [indicator, setIndicator] = useState('');

  const handleSelectedERPNFE = auxOrigin => arr => {
    const containsNFE = arr.filter(
      auxArrFilter => auxArrFilter.label === 'NFE'
    );

    const removeNFE = arr.filter(auxArrFilter => auxArrFilter.label !== 'NFE');

    if (auxOrigin === 'erp' && containsNFE.length) {
      return containsNFE;
    }

    if (auxOrigin !== 'erp' && containsNFE.length) {
      return removeNFE;
    }

    return arr;
  };

  const handleDeleteIntegration = state => action => {
    if (!state) return;

    const { actionLabel, func } = action;

    return func(actionLabel);
  };

  const handleReprocessIntegration = indicatorValidator => state => actionData => async func => {
    if (!state) return;

    if (!indicatorValidator) {
      toast.warn('Indicador deve ser selecionado');
      return;
    }

    close();
    await func(actionData);
  };

  const handleConfirmedOrigin = (
    originValidator,
    dataValidator
  ) => setState => setSwipIndex => {
    if (!originValidator) {
      toast.warn('Origem deve ser selecionada');
      return;
    }

    if (originValidator && !dataValidator[`${originValidator}_id`]) {
      const capitalize =
        originValidator[0].toUpperCase() + originValidator.slice(1);
      toast.warn(`${capitalize} não possui registro.`);
      return;
    }

    return {
      setState: setState(true),
      setSwipIndex: setSwipIndex(1),
    };
  };

  function handleChangeOrigin(newOrigin) {
    setOrigin(newOrigin);
  }

  function handleChangeIndicator(newIndicator) {
    setIndicator(newIndicator);
  }

  useEffect(() => {
    if (close) {
      setDeleteConfirm(false);
      setReprocessConfirm(false);
      setIndex(0);
    }
  }, [close]);

  return (
    <Container ref={ref}>
      <Form formRef={formRef}>
        {(current_accesses.roles['farm.admin'] ||
          current_accesses.roles['farm.manager'] ||
          current_accesses.permissions[`farm.${routeName}/:uuid.patch`]) && (
          <>
            <MenuHeadText
              primary={
                <MenuHeadContainer>
                  <p>Reprocessando pedido</p>
                  <Chip label={data?.id} />
                </MenuHeadContainer>
              }
              secondary="O reprocessamento por item é utilizado para buscar novamente os dados da origem."
            />
            <SwipeableViews index={index}>
              <SelectorText
                primary="Origem"
                secondary={
                  <RadioGroup
                    name="origin"
                    options={originOptions}
                    label=""
                    onChange={handleChangeOrigin}
                  />
                }
              />
              <SelectorText
                primary="Qual campo deseja reprocessar?"
                secondary={
                  <CheckBoxContainer>
                    <RadioGroup
                      required
                      name="indicator"
                      options={handleSelectedERPNFE(origin)(indicatorOptions)}
                      label=""
                      onChange={handleChangeIndicator}
                    />
                  </CheckBoxContainer>
                }
              />
            </SwipeableViews>
          </>
        )}

        <Divider
          style={{
            margin: '1em 0',
          }}
        />
        <MenuButtonContainer>
          {(current_accesses.roles['farm.admin'] ||
            current_accesses.roles['farm.manager'] ||
            current_accesses.permissions[`farm.${routeName}.delete`]) && (
            <MenuButton
              remove="true"
              onClick={() =>
                !deleteConfirm
                  ? setDeleteConfirm(true)
                  : handleDeleteIntegration(deleteConfirm)(removeAction)
              }
            >
              {!deleteConfirm ? (
                <DeleteForever color="error" />
              ) : (
                <ErrorOutline color="error" />
              )}
            </MenuButton>
          )}

          {generateJSONAction && (
            <MenuButton
              json="true"
              onClick={() =>
                !reprocessConfirm
                  ? handleConfirmedOrigin(origin, data)(setReprocessConfirm)(
                      setIndex
                    )
                  : handleReprocessIntegration(indicator)(reprocessConfirm)({
                      indicator,
                      row: data.uuid,
                      origin,
                    })(generateJSONAction)
              }
            >
              JSON
            </MenuButton>
          )}

          {(current_accesses.roles['farm.admin'] ||
            current_accesses.roles['farm.manager'] ||
            current_accesses.permissions[`farm.${routeName}/:uuid.patch`]) && (
            <MenuButton
              onClick={() =>
                !reprocessConfirm
                  ? handleConfirmedOrigin(origin, data)(setReprocessConfirm)(
                      setIndex
                    )
                  : handleReprocessIntegration(indicator)(reprocessConfirm)({
                      indicator,
                      row: data.uuid,
                      origin,
                    })(submitAction)
              }
            >
              {!reprocessConfirm ? 'Selecionar' : 'Confirmar'}
            </MenuButton>
          )}
        </MenuButtonContainer>
      </Form>
    </Container>
  );
}

export default forwardRef(ReprocessUnitaryDialog);

ReprocessUnitaryDialog.propTypes = {
  close: PropTypes.func.isRequired,
  indicatorOptions: PropTypes.arrayOf(PropTypes.shape({})),
  originOptions: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape().isRequired,
  removeAction: PropTypes.shape().isRequired,
  generateJSONAction: PropTypes.func,
  submitAction: PropTypes.func.isRequired,
};

ReprocessUnitaryDialog.defaultProps = {
  indicatorOptions: [],
  originOptions: [],
  generateJSONAction: null,
};
