function parseArrayValidations(arrayValidations = []) {
  const validations = {};

  let rest = '';
  arrayValidations.forEach(validation => {
    if (!validations[validation.field]) {
      validations[validation.field] = validation.message;

      rest += `${validation.field}:\n- ${validation.message}\n`;
    } else rest += `- ${validation.message}\n`;
  });

  return { validations, rest };
}

export const parseIntegrationArrayValidations = arrayValidations => {
  let text = '';

  arrayValidations?.forEach?.(validation => {
    const { field, message } = validation;

    if (field) {
      text += `${message}: ${field}<br />`;
      return;
    }

    text += message;
  });

  return text;
};

export default function errorHandler({
  error = { message: '', title: '', local: '' },
  title = 'Ocorreu um error',
  local = 'local',
}) {
  let errorParsed = error;

  if (!errorParsed.title && title) errorParsed.title = title;
  if (!errorParsed.local && local) errorParsed.local = local;

  if (error.response && error.response.data && error.response.data.error) {
    errorParsed = { ...error.response.data.error };

    if (
      errorParsed.code === 'E_JWT_TOKEN_EXPIRED' ||
      errorParsed.code === 'E_LIMIT_ACCESS'
    ) {
      errorParsed.title = 'Acesso expirado';
      errorParsed.message =
        'Acesso expirado ou limite atingido, deseja continuar assim mesmo ?';
    } else if (error.response.status === 500) {
      errorParsed.title = 'Algo deu errado';
      errorParsed.message = 'Algo deu errado, contate o administrador';
    } else if (errorParsed.code === 'E_VALIDATION_FAILED') {
      const { validations, rest } = parseArrayValidations(
        errorParsed.validations
      );

      errorParsed.validations = validations;
      errorParsed.rest = rest;
    }
  } else if (error.code === 'E_VALIDATION_FAILED') {
    const { validations, rest } = parseArrayValidations(error.validations);
    errorParsed.validations = validations;
    errorParsed.rest = rest;
  } else if (error.message === 'Network Error') {
    errorParsed.message =
      'Sem conexão. Favor conectar à internet para prosseguir';
  } else if (error.code === 'E_ROUTE_NOT_FOUND') {
    errorParsed.title = error.title;

    errorParsed.message = `${
      error.message || `Error em ${error.local || local}`
    }`;
  } else {
    errorParsed.message = `${
      error.message || `Error em ${error.local || local}`
    }`;
  }

  if (process.env.NODE_ENV === 'development') {
    console.log({ errorHandler: errorParsed });
  }
  return errorParsed;
}
