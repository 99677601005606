import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import { SharedDestroyAppRepository } from '../repositories/app';

class SharedDestroyAppService {
  constructor() {
    this.sharedDestroyAppRepository = new SharedDestroyAppRepository();
  }

  async execute({ entityName = '', dataObj = {} }) {
    try {
      const sharedData = await this.sharedDestroyAppRepository.execute({
        entityName,
        dataObj,
      });

      return sharedData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Error ao remover ${
          dictionaryEntity({ entityName }) || 'Dados'
        }`,
        local: `SharedDestroyAppService`,
      };

      throw errors;
    }
  }
}

export default SharedDestroyAppService;
