import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import errorHandler from '~/shared/errors/handler';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedDestroyAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({ entityName = '', dataObj = {} }) {
    try {
      const { data } = await this.apiApp({
        url: `${apiApp.defaults.baseURL}/${plural(entityName).replace(
          '_',
          '-'
        )}`,
        method: 'delete',
        data: dataObj,
      }).then(res => res.data);

      if (data[0].status !== 200) {
        const { error } = data[0];

        throw error;
      }

      return { ...data[0][entityName] };
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error ao remover um ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: `SharedDestroyAppRepository`,
      });

      throw errors;
    }
  }
}

export default SharedDestroyAppRepository;
