import { useRef, useEffect, useCallback } from 'react';
import { Container, CodeAuth } from './styles';

import { useSession } from '~/modules/sessions/hooks/useSession';

const CodeAuthLogin = () => {
  const iFrameRef = useRef(null);

  const {
    token,
    current_accesses,
    logged_branch,
    signOut,
    refreshTokenFromExternalApp,
    contract: auxContract,
  } = useSession();

  const { token: auxToken, type, refreshToken } = token;

  const appOrigin = process.env.REACT_APP_APP_URL;

  const application_key = process.env.REACT_APP_APPLICATION_KEY;
  const contract = {
    uuid: auxContract?.uuid,
    contract_key: auxContract?.contract_key,
  };

  const loggedBranch = {
    company_key: logged_branch.company_key,
    branch_key: logged_branch.branch_key,
  };

  const tokenParams = [
    auxToken,
    type,
    refreshToken,
    JSON.stringify(contract),
    appOrigin,
    JSON.stringify(current_accesses),
    application_key,
    JSON.stringify(loggedBranch),
    'settings',
  ]
    .map(param => `param=${param}`)
    .join('&');

  const handleMessageCodeAuth = useCallback(
    async event => {
      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'singnOutCodeAuth'
      ) {
        await signOut();
      }

      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data.token
      ) {
        refreshTokenFromExternalApp({ token: event.data.token });
      }
    },
    [signOut, refreshTokenFromExternalApp]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessageCodeAuth);
    return () => window.removeEventListener('message', handleMessageCodeAuth);
  }, [handleMessageCodeAuth]);

  return (
    <Container>
      <CodeAuth
        ref={iFrameRef}
        src={`${process.env.REACT_APP_CODE_AUTH_URL}/login?${tokenParams}`}
        title="CodeAuth"
      />
    </Container>
  );
};

export default CodeAuthLogin;
