export const entityName = 'product';

export const tableListOptions = {
  headCells: [
    { id: 'id', label: 'Código Farm', exclude: true, isId: true },
    { id: 'ecommerce_id', label: 'Código E-commerce', exclude: true },
    { id: 'erp_id', label: 'Código ERP', exclude: true },
  ],

  typesOptions: [
    { id: 'process_all_force', name: 'Reprocesso Forçado' },
    { id: 'process_all', name: 'Reprocesso Total' },
  ],

  reprocessOptions: [
    { id: 'product', name: 'Produtos' },
    { id: 'stock', name: 'Estoques' },
  ],

  otherCells: [
    { id: 'attempts_view', label: 'Tentativas de reprocesso', exclude: true },
    { id: 'name', label: 'Nome', exclude: true },
    { id: 'stock_balance', label: 'Saldo em Estoque', exclude: true },
    { id: 'zip_code_start_at', label: 'CEP Inicial', exclude: true },
    { id: 'zip_code_end_at', label: 'CEP Final', exclude: true },
  ],

  filtersOptions: [
    { id: 'id', label: 'Código Farm', type: 'string' },
    { id: 'erp_id', label: 'Código ERP', type: 'string' },
    { id: 'ecommerce_id', label: 'Código E-COMMERCE', type: 'string' },
    { id: 'erp_process_at', label: 'Data processo ERP', type: 'date' },
    {
      id: 'ecommerce_process_at',
      label: 'Data processo E-COMMERCE',
      type: 'date',
    },
    { id: 'updated_at', label: 'Data processo Farm', type: 'date' },
    { id: 'error_message', label: 'Mensagem de Erro', type: 'string' },
    { id: 'name', label: 'Nome', type: 'string' },
  ],
};
