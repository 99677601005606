export function parseToBase64(file) {
  if (!file) return new Promise(resolve => resolve());
  const reader = new FileReader();

  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onabort = () => resolve();
    reader.onerror = () => reject(reader.error);
  });
}

export function downloadAsText(text) {
  const a = document.createElement('a');
  a.href = `data:text/plain;charset=UTF-8,${text}`;
  a.download = 'registro.txt';
  a.click();
}
