export const cpfMask = value => {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') || ''
  );
};

export const cnpjMask = value => {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') || ''
  );
};

export const zipCodeMask = value => {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1') || ''
  );
};

export const phoneMask = value => {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1') || ''
  );
};

export const cellMask = value => {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1') || ''
  );
};

export const numberUnmask = value => {
  return String(value || '').replace(/\D/g, '') || '';
};

export const numberMask = value => {
  return Number(String(value || '').replace(/\D/g, '')) || '';
};

export const valueMask = (value, scale) => {
  if (!value) return '';

  const [auxValue, auxScale] = (Number(value) || 0)
    .toFixed(scale || 2)
    .split('.');

  return `${auxValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')},${auxScale}`;
};

export const valueUnmask = value => {
  return (
    Number(
      String(value || 0)
        .replace(/\./g, '')
        .replace(/,/g, '.')
        .replace(/[^0-9.]/g, '')
    ) || ''
  );
};

export const Unmask = value => {
  return (
    (value || '')
      .replace(/\./g, '')
      .replace(/,/g, '')
      .replace(/-/g, '')
      .replace(/\//g, '') || ''
  );
};
