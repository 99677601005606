import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

import errorHandler from '~/shared/errors/handler';

class SharedReprocessUnitaryAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({ entityName = '', indicator = '', row = {}, origin = '' }) {
    try {
      await this.apiApp
        .patch(
          `${plural(entityName).replace('_', '-')}/${row}`,
          {},
          { params: { origin, type: 'process_one', indicator } }
        )
        .then(res => res.data);

      return {};
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error no reprocesso unitário de ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `SharedReprocessUnitaryAppRepository`,
      });

      throw errors;
    }
  }
}

export default SharedReprocessUnitaryAppRepository;
