import { plural } from 'pluralize';
import apiApp from '~/config/apiApp';

import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

import errorHandler from '~/shared/errors/handler';
import { downloadAsText } from '~/shared/providers/utils/file';

class SharedGenerateJSONAppRepository {
  constructor() {
    this.apiApp = apiApp;
  }

  async execute({ entityName = '', indicator = '', row = {}, origin = '' }) {
    try {
      const response = await this.apiApp
        .patch(
          `${plural(entityName).replace('_', '-')}/${row}`,
          {},
          { params: { origin, type: 'generate_json', indicator } }
        )
        .then(res => res.data);

      const defaultError = {
        error: 'Ocorreu um erro ao buscar as informações',
      };

      const dataJSON = {
        data: response?.data?.memory_data?.[plural(entityName)] || defaultError,
      };
      const stringJSON = JSON.stringify(dataJSON, null, 2);

      downloadAsText(stringJSON);

      return {};
    } catch (error) {
      const errors = errorHandler({
        error,
        title: `Error no gerar JSON de ${
          dictionaryEntity({
            entityName,
          }) || 'Dados'
        }`,
        local: `SharedGenerateJSONAppRepository`,
      });

      throw errors;
    }
  }
}

export default SharedGenerateJSONAppRepository;
