import React, { createContext, useState, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  SharedListAppService,
  SharedShowAppService,
  SharedUpdateAppService,
  SharedStoreAppService,
  SharedFormDataAppService,
  SharedDestroyAppService,
  SharedIndexSelectorAppService,
} from '~/shared/modules/services';

import { entityName } from '../constantVariables/parameter';
import { useErrorHandler } from '~/shared/errors/hook';
import {
  parseDataToDestroy,
  parseDataToStoreOrUpdate,
} from '../sanitizers/parameter';

const INITIAL_STATE = {
  parameter: {},
  parameterErrors: {},
  parameterLoading: false,
  parameterListLoading: false,
};

const sharedListAppService = new SharedListAppService();
const sharedShowAppService = new SharedShowAppService();
const sharedUpdateAppService = new SharedUpdateAppService();
const sharedStoreAppService = new SharedStoreAppService();
const sharedFormDataAppService = new SharedFormDataAppService();
const sharedDestroyAppService = new SharedDestroyAppService();
const sharedIndexSelectorAppService = new SharedIndexSelectorAppService();

const ParameterContext = createContext(INITIAL_STATE);

export function ParameterProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();
  const [data, setData] = useState(INITIAL_STATE);

  const setParameterData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const formData = useCallback(async () => {
    try {
      setParameterData({ parameterListLoading: true });
      const parameterData = await sharedFormDataAppService.execute({
        entityName,
      });

      setParameterData({ parameterListLoading: false });

      return parameterData;
    } catch (err) {
      setErrorHandlerData({
        ...err,
        resolveFunction: () => formData(),
      });
      setParameterData({ parameterListLoading: false });

      return {};
    }
  }, [setErrorHandlerData, setParameterData]);

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      try {
        setParameterData({ parameterListLoading: true });
        const parameterData = await sharedIndexSelectorAppService.execute({
          entityName,
          search,
        });

        setParameterData({ parameterListLoading: false });

        return parameterData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => indexSelector({ search }),
        });
        setParameterData({ parameterListLoading: false });

        return {};
      }
    },
    [setErrorHandlerData, setParameterData]
  );

  const index = useCallback(
    async ({
      search = '',
      order_by = '',
      order = '',
      page = 1,
      filter_by = '',
    }) => {
      setParameterData({ parameterListLoading: true });

      let parameterData = { total: 1, data: [] };

      try {
        parameterData = await sharedListAppService.execute({
          entityName,
          search,
          order_by,
          order,
          page,
          filter_by,
        });

        setParameterData({ ...parameterData });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => index({ search, order_by, order }),
        });
      }
      setParameterData({ parameterListLoading: false });

      return parameterData;
    },
    [setParameterData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ dataObj = {} }) => {
      setParameterData({ parameterLoading: true });
      try {
        const parameterData = await sharedShowAppService.execute({
          entityName,
          dataObj,
        });

        const parsedData = {
          parameter: {
            ...parameterData.parameter,
            indicators: parameterData.indicators,
          },
        };

        setParameterData({ ...parsedData, parameterLoading: false });
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => show({ dataObj }),
        });

        setParameterData({ parameterLoading: false });
      }
    },
    [setParameterData, setErrorHandlerData]
  );

  const store = useCallback(
    async ({ dataObj = {} }) => {
      setParameterData({
        parameterLoading: true,
        parameterErrors: {},
      });

      let parameterData = { total: 1, data: [] };
      try {
        parameterData = await sharedStoreAppService.execute({
          entityName,
          dataObj: parseDataToStoreOrUpdate(dataObj),
        });

        history.goBack();

        setParameterData({ parameterLoading: false, ...parameterData });

        toast.success('Parâmetro cadastrado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => store({ dataObj }),
        });

        setParameterData({ parameterLoading: false });

        throw err;
      }
    },
    [setParameterData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ dataObj = {} }) => {
      setParameterData({ parameterLoading: true });
      let parameterData = { total: 1, data: [] };

      try {
        parameterData = await sharedUpdateAppService.execute({
          entityName,
          dataObj: parseDataToStoreOrUpdate(dataObj),
        });

        setParameterData({ parameterLoading: false, ...parameterData });
        history.goBack();

        toast.success('Parâmetro atualizado com sucesso!');
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => update({ dataObj }),
        });
        setParameterData({ parameterLoading: false });
      }
    },
    [setParameterData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ dataObj = {} }) => {
      try {
        setParameterData({ parameterListLoading: true });

        const parameterData = await sharedDestroyAppService.execute({
          entityName,
          dataObj: parseDataToDestroy(dataObj),
        });

        setParameterData({ parameterListLoading: false });
        toast.success('Parâmetro removido com sucesso!');

        return parameterData;
      } catch (error) {
        setErrorHandlerData({
          ...error,
          resolveFunction: () => destroy({ dataObj }),
        });

        setParameterData({ parameterListLoading: false });
        return {};
      }
    },
    [setParameterData, setErrorHandlerData]
  );

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        parameter: {},
        parameterErrors: {},
        parameterLoading: false,
        parameterListLoading: false,
      };
    });
  }, []);

  return (
    <ParameterContext.Provider
      value={{
        ...data,
        setParameterData,
        index,
        show,
        store,
        update,
        destroy,
        formData,
        clearState,
      }}
    >
      {children}
    </ParameterContext.Provider>
  );
}

export function useParameter() {
  const context = useContext(ParameterContext);

  if (!context)
    throw new Error('useParameter must be used within an ParameterProvider');

  return context;
}

ParameterProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
