import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { AddAPhoto } from '@material-ui/icons';

import { parseToBase64 } from '~/shared/providers/utils/file';

import ImageContainer from '../ImageContainer';
import { ButtonImage } from './styles';

function ImagePicker({
  name,
  helperText,
  required,
  maxLength,
  disabled,
  endAdornment,
  ...rest
}) {
  const { fieldName, registerField, error, clearError } = useField(name);

  const inputRef = useRef({ value: '' });

  const [selectedImage, setSelectedImage] = useState('');

  const handleChange = useCallback(
    async event => {
      if (
        event.target?.files.length &&
        event.target?.files[0].type.startsWith('image/')
      ) {
        const base64Data = await parseToBase64(
          event.target.files[0]
        ).catch(err => alert(JSON.stringify(err && err.message)));

        setSelectedImage(base64Data);
        inputRef.current.value = base64Data;
      }

      if (error) clearError();
    },
    [clearError, error]
  );

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = selectedImage;
  }, [selectedImage]);

  useEffect(() => {
    inputRef.current.handleChange = setSelectedImage;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, handleChange]);

  return (
    <FormControl
      error={!!error}
      size="small"
      variant="outlined"
      style={{ width: '200px' }}
    >
      <ButtonImage disabled={disabled} style={{ width: '100%' }}>
        <label htmlFor="image" style={{ cursor: 'pointer' }}>
          <ImageContainer
            style={{ width: '140px', height: '140px', borderRadius: '4px' }}
            src={[selectedImage]}
            alt="imagem"
          />
          <AddAPhoto
            fontSize="large"
            style={{ position: 'absolute', bottom: 4, right: '16%' }}
          />
          <input
            id="image"
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleChange}
            style={{ display: 'none' }}
            {...rest}
          />
        </label>
      </ButtonImage>
      <FormHelperText>{error || helperText || ' '}</FormHelperText>
    </FormControl>
  );
}

export default ImagePicker;

ImagePicker.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  endAdornment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ImagePicker.defaultProps = {
  helperText: '',
  required: false,
  disabled: false,
  maxLength: null,
  endAdornment: null,
};
