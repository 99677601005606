import { useEffect, useState, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { useLocation, useHistory } from 'react-router-dom';
import { useSession } from '~/modules/sessions/hooks/useSession';

import Illustration from '~/shared/assets/background.svg';
import LogoApp from '~/shared/assets/LogoFarm.png';
import SignIn from '../SignIn';
import Branch from '../Branch';
import ForgotPassword from '../ForgotPassword';
import RecoverPassword from '../RecoverPassword';
import { Container, LoginContainer, LogoContainer } from './styles';

const SignInContent = () => {
  const { sessionPageIndex } = useSession();

  const [index, setIndex] = useState(3);

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);

  const handleRecoverOrForgotPage = query.get('password_token');

  const handleIframeToast = useCallback(
    event => {
      if (
        event.origin === `${process.env.REACT_APP_CODE_AUTH_URL}` &&
        event.data === 'sendUpdatePasswordSuccess'
      ) {
        setIndex(1);
        history.replace({ search: '' });
      }
    },
    [history]
  );

  useEffect(() => {
    setIndex(sessionPageIndex);
  }, [sessionPageIndex]);

  useEffect(() => {
    if (handleRecoverOrForgotPage) setIndex(0);
  }, [handleRecoverOrForgotPage]);

  useEffect(() => {
    window.addEventListener('message', handleIframeToast);
    return () => window.removeEventListener('message', handleIframeToast);
  }, [handleIframeToast]);

  return (
    <Container background={Illustration}>
      <LogoContainer>
        <img src={LogoApp} alt="Logo App" />
      </LogoContainer>
      <LoginContainer>
        <SwipeableViews index={index}>
          {handleRecoverOrForgotPage ? (
            <RecoverPassword setIndex={setIndex} />
          ) : (
            <ForgotPassword setIndex={setIndex} />
          )}
          <SignIn setIndex={setIndex} />
          <Branch setIndex={setIndex} />
        </SwipeableViews>
      </LoginContainer>
    </Container>
  );
};

export default SignInContent;
