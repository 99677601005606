import { useCallback } from 'react';

import { SharedIndexSelectorAppService } from '~/shared/modules/services';

import { useErrorHandler } from '~/shared/errors/hook';

const sharedIndexSelectorAppService = new SharedIndexSelectorAppService();

const entityName = 'indicators';

export const useIndicator = () => {
  const { setErrorHandlerData } = useErrorHandler();

  const indexSelector = useCallback(
    async ({ search = '' }) => {
      try {
        const parameterData = await sharedIndexSelectorAppService.execute({
          entityName,
          search,
        });

        return parameterData;
      } catch (err) {
        setErrorHandlerData({
          ...err,
          resolveFunction: () => indexSelector({ search }),
        });

        return {};
      }
    },
    [setErrorHandlerData]
  );

  return { indexSelector };
};
