import { plural } from 'pluralize';
import { SharedFormDataAppRepository } from '../repositories/app';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';

class SharedFormDataAppService {
  constructor() {
    this.sharedFormDataAppRepository = new SharedFormDataAppRepository();
  }

  async execute({ entityName = '', parser = null }) {
    try {
      const baseData = await this.sharedFormDataAppRepository.execute({
        entityName,
        parser,
      });

      return baseData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao listar ${dictionaryEntity({
          entityName: plural(entityName),
        })}`,
        local: 'SharedFormDataAppService',
      };

      throw errors;
    }
  }
}

export default SharedFormDataAppService;
