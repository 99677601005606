export function parseDataToStoreOrUpdate(parameter = {}) {
  return {
    uuid: parameter.uuid,
    name: parameter.name,
    company_key: parameter.company_key,
    branch_key: parameter.branch_key,
    description: parameter.description,
    key: parameter.key,
    value: parameter.value,
    indicator: parameter.indicator,
    req_id: '1',
  };
}

export function parseDataToDestroy(parameter = {}) {
  return {
    data: [
      {
        uuid: parameter.uuid,
        company_key: parameter.company_key,
        branch_key: parameter.branch_key,
        req_id: parameter.req_id,
      },
    ],
  };
}

export function parseFormData(parameter = {}) {
  return {
    uuid: parameter.uuid,
    name: parameter.name,
    description: parameter.description,
    key: parameter.key,
    company_key: parameter.company_key,
    branch_key: parameter.branch_key,
    value: parameter.value,
    indicator: parameter.indicator,
  };
}
