import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form).attrs({})`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 6px;
`;
