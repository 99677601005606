import {
  useRef,
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSession } from '~/modules/sessions/hooks/useSession';
import { debounceEvent } from '~/shared/providers/utils/debounce';

import TableListToolbar, { newItemPropType } from './Toolbar';
import TableListHead, {
  cellsPropType,
  selectPropType,
  actionsPropType,
} from './Head';
import TableListBody from './Body';

export { WrapperContainer } from './styles';

const PER_PAGE = 50;

const useTableStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: 700,
    display: 'flex',
    height: '99%',
    position: 'relative',
  },
  loadingWrapper: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    top: 70,
    bottom: 70,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    height: 'calc(100vh - 230px)',
  },
  tableContainerDash: {
    height: 'calc(50vh - 80px)',
  },
  table: {
    minWidth: 100,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableList(
  {
    title,
    newItem,
    headCells,
    otherCells,
    observationCells,
    customRowHeadCells,
    customRowOtherCells,
    loadData,
    loading,
    actions,
    reprocessModal,
    reprocessFields,
    reprocessSubmit,
    generateJSON,
    select,
    canEdit,
    filtersOptions,
    idFilterInitial,
  },
  ref
) {
  const classes = useTableStyles();

  const { token } = useSession();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [oldToken, setOldToken] = useState(() => {
    return token.token;
  });

  const searchRef = useRef({ value: '' });

  const rows = useMemo(
    () =>
      data?.map(item => {
        const itemParsed = { ...item };

        if (otherCells)
          otherCells.forEach(otherCell => {
            itemParsed[otherCell.id] = item[otherCell.id];
          });

        if (observationCells)
          observationCells.forEach(observationCell => {
            itemParsed[observationCell.id] = item[observationCell.id];
          });

        return itemParsed;
      }),
    [otherCells, observationCells, data]
  );

  const handleChangeSearchText = ({ filter_by, text }) => {
    debounceEvent(async () => {
      if (searchRef.current) searchRef.current.value = text;

      const loadedData = await loadData(
        text,
        orderBy,
        order,
        page + 1,
        filter_by
      );

      setPage(0);
      setCount(Number(loadedData.total));
      setData(loadedData.data);
    }, 500)();
  };

  const handleRequestSort = async (event, property) => {
    const orderAux = orderBy === property && order === 'asc' ? 'desc' : 'asc';

    const loadedData = await loadData(
      searchRef.current && searchRef.current.value,
      property,
      orderAux
    );

    setCount(Number(loadedData.total));
    setData(loadedData.data);

    setOrder(orderAux);
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      debounceEvent(async () => {
        const loadedData = await loadData(
          searchRef.current && searchRef.current.value,
          orderBy,
          order,
          newPage + 1
        );

        const filteredData = loadedData.data.filter(
          dt => !data.some(item => item.id === dt.id)
        );

        setCount(Number(loadedData.total));
        setData([...data, ...filteredData]);
        setPage(newPage);
      }, 500)();
    } else setPage(newPage);
  };

  const handleRemoveData = ({ key, value }) => {
    setData(oldData => oldData.filter(item => item[key] !== value[key]));
    setCount(oldData => (oldData > 0 ? oldData - 1 : 0));
  };

  useEffect(() => {
    setOldToken(token.token);
    const load = async () => {
      const loadedData = await loadData();

      setCount(Number(loadedData.total));
      setData(loadedData.data);
    };

    if ((!loaded && data.length <= 1) || token.token !== oldToken) load();

    setLoaded(true);
  }, [data, loadData, loaded, token, oldToken]);

  useImperativeHandle(ref, () => ({ handleRemoveData }));

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      )}
      <Paper className={classes.paper}>
        <TableListToolbar
          title={title}
          newItem={newItem}
          searchRef={searchRef}
          handleChangeSearchText={handleChangeSearchText}
          canEdit={canEdit}
          filtersOptions={filtersOptions}
          idFilterInitial={idFilterInitial}
        />
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader size="small" className={classes.table}>
            <TableListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
              actions={actions}
              select={select}
            />
            <TableListBody
              rows={rows}
              page={page}
              rowsPerPage={PER_PAGE}
              headCells={headCells}
              otherCells={otherCells}
              observationCells={observationCells}
              data={data}
              reprocessModal={reprocessModal}
              reprocessFields={reprocessFields}
              reprocessSubmit={reprocessSubmit}
              generateJSON={generateJSON}
              actions={actions}
              select={select}
              customRowHeadCells={customRowHeadCells}
              customRowOtherCells={customRowOtherCells}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[PER_PAGE]}
          component="div"
          count={count}
          rowsPerPage={PER_PAGE}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}

export default forwardRef(TableList);

TableList.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  newItem: newItemPropType,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  reprocessModal: PropTypes.arrayOf(PropTypes.shape({})),
  reprocessFields: PropTypes.arrayOf(PropTypes.shape({})),
  reprocessSubmit: PropTypes.func,
  generateJSON: PropTypes.func,
  observationCells: cellsPropType,
  customRowHeadCells: PropTypes.func,
  customRowOtherCells: PropTypes.func,
  loadData: PropTypes.func,
  loading: PropTypes.bool,
  actions: actionsPropType,
  select: selectPropType,
  canEdit: PropTypes.bool,
  filtersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  idFilterInitial: PropTypes.string,
};

TableList.defaultProps = {
  newItem: null,
  otherCells: null,
  observationCells: null,
  customRowHeadCells: null,
  customRowOtherCells: null,
  loadData: () => {},
  loading: false,
  select: null,
  actions: null,
  reprocessModal: [],
  reprocessFields: [],
  reprocessSubmit: null,
  generateJSON: null,
  canEdit: true,
  filtersOptions: [],
  idFilterInitial: '',
};
