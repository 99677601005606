import { plural } from 'pluralize';
import dictionaryEntity from '~/shared/providers/utils/dictionaryEntity';
import { SharedReprocessAllAppRepository } from '../repositories/app';

class SharedProcessAllAppService {
  constructor() {
    this.sharedReprocessAllAppRepository = new SharedReprocessAllAppRepository();
  }

  async execute({ entityName = '', type = '', indicator = '', start_at = '' }) {
    try {
      const reprocessAllData = await this.sharedReprocessAllAppRepository.execute(
        {
          entityName,
          type,
          indicator,
          start_at,
        }
      );

      return reprocessAllData;
    } catch (error) {
      const errors = {
        ...error,
        title: `Erro ao reprocessar ${
          dictionaryEntity({
            entityName: plural(entityName),
          }) || 'Dados'
        }`,
        local: 'SharedProcessAllAppService',
      };

      throw errors;
    }
  }
}

export default SharedProcessAllAppService;
