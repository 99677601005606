import { SessionAppRepository } from '../repositories/app';

class SessionAppService {
  constructor() {
    this.sessionAppRepository = new SessionAppRepository();
  }

  async store({ email = '', password = '', branch_key = '', force = false }) {
    try {
      const session = await this.sessionAppRepository.store({
        email,
        password,
        branch_key,
        force,
      });
      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error ao fazer Session',
        local: 'SessionAppServiceStore',
      };

      throw errors;
    }
  }

  async changeBranch({ branch_key = '', force = false }) {
    try {
      const session = await this.sessionAppRepository.changeBranch({
        branch_key,
        force,
      });

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error ao mudar de Empresa',
        local: 'SessionAppServiceChangeBranch',
      };

      throw errors;
    }
  }

  async refreshToken() {
    try {
      const session = await this.sessionAppRepository.refreshToken();

      return session;
    } catch (error) {
      const errors = {
        ...error,
        title: 'Error tentar entrar novamente',
        local: 'SessionAppServiceRefreshToken',
      };

      throw errors;
    }
  }
}

export default SessionAppService;
